export const ENDOCRINE_IMMUNE_ANSWERS = [
  [
    'A1FA2401-8572-462A-906F-FAD7A7F1FB03',
    ['WEAK_KIDNEY_FUNCTION_CHRONIC_KIDNEY_DISEASE_RENAL_INSUFFICIENCY'],
  ],
  [
    'CDE0F7C2-7561-4A80-B5A8-87BA556C3F24',
    [
      'I_NEVER_APPLIED_FOR_THIS_AND_I_DID_NOT_HAVE_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
      'I_NEVER_APPLIED_FOR_THIS_BUT_I_HAD_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
    ],
  ],
  ['99B0A94C-4225-4A98-BA34-32AE08238720', ['HYPOTHYROIDISM']],
  [
    '92F25392-9CB0-4FAC-B7AD-4C7C90A075F8',
    [
      'I_NEVER_APPLIED_FOR_THIS_BUT_I_HAD_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
      'I_NEVER_APPLIED_FOR_THIS_AND_I_DID_NOT_HAVE_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
    ],
  ],
  ['96CCE4FF-7B5F-4F9E-822A-89DDB1D77E7D', ['CHRONIC_FATIGUE_SYNDROME']],
  [
    'CA6C8C5A-FC20-4CD1-A04D-A49FF10FDE2C',
    [
      'I_NEVER_APPLIED_FOR_THIS_AND_I_DID_NOT_HAVE_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
      'I_NEVER_APPLIED_FOR_THIS_BUT_I_HAD_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
    ],
  ],
];
