import { UIAlert } from '@team-and-tech/ui-components';

const DailyLifeDisabilityHeader = () => {
  return (
    <>
      <UIAlert
        contentNode="The following question refers to a potential VA mental health disability benefit which you may qualify for based on a current service-connected disability which has a negative impact on your mental well-being. An example of this could be a service-connected joint or spinal condition which causes daily chronic pain, which leads to anxiety or depression and impacts your daily life and relationships. Many other disabilities can lead to feelings of inadequacy or frustration at missing out on activities you used to enjoy, or overall feelings of not being whole, capable, or strong anymore.​"
        severity="info"
      />
    </>
  );
};

export default DailyLifeDisabilityHeader;
