export default function TrajectorLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46.5"
      height="30"
      viewBox="0 0 46.5 30"
      fill="none"
    >
      <polygon
        className="st0"
        fill="#184997"
        points="4,6.6 36,6.6 29.6,11.1 21.8,11.1 21.8,29.5 16.8,29.5 16.8,11.1 7,11.1 "
      />
      <path
        className="st1"
        fill="#C31733"
        d="M6,29.5h3.9c0,0,7.1-5.7,31-20.1c0.6,1.8,1.2,4.2,1.2,4.2c0.1,0.3,0.4,0,0.4,0L46.6,0C46.6,0,17.8,19.8,6,29.5z
	"
      />
    </svg>
  );
}
