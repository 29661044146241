import { useCallback, useEffect, useState } from 'react';

const usePagination = (numPages: number = 1, startPage?: number) => {
  const [currentPage, setCurrentPage] = useState<number>(startPage || 1);

  // reset viewport scroll on page change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const increment = useCallback(() => {
    if (currentPage === numPages) {
      return;
    }
    setCurrentPage(currentPage + 1);
  }, [currentPage, numPages]);

  const decrement = useCallback(() => {
    if (currentPage === 1) {
      return;
    }
    setCurrentPage(currentPage - 1);
  }, [currentPage]);

  return { currentPage, setCurrentPage, increment, decrement };
};

export default usePagination;
