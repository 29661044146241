import { Question, InputType } from '../questions/types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

const validateZip = (value: string) =>
  String(value)
    .toLowerCase()
    .match(/^\d{5}$/) ||
  String(value)
    .toLowerCase()
    .match(/^\d{5}-\d{4}$/);

const validateSsn = (value: string) =>
  String(value)
    .toLowerCase()
    .match(/^\d{3}-\d{2}-\d{4}$/);

const validateServicePeriodYears = (
  answersMap: Map<string, any>,
  errors: Map<string, any>,
  entryQuestionId: string,
  exitQuestionId: string,
) => {
  const entry = answersMap.get(entryQuestionId);
  const exit = answersMap.get(exitQuestionId);
  if (!!entry && !!exit) {
    if (entry > exit) {
      errors.set(
        entryQuestionId,
        'Entry year must be less than or equal to exit year.',
      );
      errors.set(
        exitQuestionId,
        'Exit year must be greater than or equal to entry year.',
      );
    }
  }
};

const validateAnswers = (
  answersMap: Map<string, any>,
  questions: Question[],
) => {
  let errors = new Map();
  questions?.forEach((question) => {
    let value = answersMap.get(question.id);

    // Required validation
    if (!value && question?.isRequired) {
      errors.set(question.id, 'Required');
    }
    if (question?.isRequired || !!value) {
      // Email validation
      if (question.input_type === InputType.EMAIL) {
        !validateEmail(value) &&
          errors.set(question.id, 'Please enter a valid email address');
      }
      // Phone number validation
      if (question.input_type === InputType.PHONE) {
        value?.length !== 10 &&
          errors.set(question.id, 'Please enter a 10 digit phone number');
      }
      // Date validation
      if (question.input_type === InputType.DATE) {
        if (typeof value === 'undefined') {
          errors.set(question.id, 'Please enter a full date');
        } else if (value === null) {
          errors.set(question.id, 'Date is invalid');
        } else if (!dayjs(value).isValid()) {
          errors.set(question.id, 'Date is invalid');
        }
      }

      // Zip validation
      if (question.format === 'zipCode') {
        !validateZip(value) &&
          errors.set(
            question.id,
            'Please provide zip in 00000 or 00000-0000 format',
          );
      }

      // Ssn validation
      if (question.input_type === 'SSN') {
        !validateSsn(value) &&
          errors.set(question.id, 'Please provide valid SSN');
      }

      // Max length validation
      if (question.maxLength) {
        if (
          question.input_type === 'NUMBER' ||
          question.input_type === 'CURRENCY'
        ) {
          const maxLength =
            question.input_type === 'CURRENCY'
              ? question.maxLength + 2
              : question.maxLength;
          value?.toString()?.length > maxLength &&
            errors.set(
              question.id,
              `Must be no longer than ${question.maxLength} digits.`,
            );
        } else {
          value?.length > question.maxLength &&
            errors.set(
              question.id,
              `Must be no longer than ${question.maxLength} characters.`,
            );
        }
      }

      // Min length validation
      if (question.minLength) {
        if (
          question.input_type === 'NUMBER' ||
          question.input_type === 'CURRENCY'
        ) {
          const minLength =
            question.input_type === 'CURRENCY'
              ? question.minLength + 2
              : question.minLength;
          value?.toString()?.length < minLength &&
            errors.set(
              question.id,
              `Must be at least ${question.minLength} digits.`,
            );
        } else {
          value?.length < question.minLength &&
            errors.set(
              question.id,
              `Must be at least ${question.minLength} characters.`,
            );
        }
      }

      // Length validation
      if (question.length) {
        if (
          question.input_type === 'NUMBER' ||
          question.input_type === 'CURRENCY'
        ) {
          const length =
            question.input_type === 'CURRENCY'
              ? question.length + 2
              : question.length;
          value?.toString()?.length !== length &&
            errors.set(question.id, `Must be ${question.length} digits.`);
        } else {
          value?.length !== question.length &&
            errors.set(question.id, `Must be ${question.length} characters.`);
        }
      }
    }
  });
  //
  // BEGIN question-specific validation that requires no iteration
  //

  // Background page 1 discharge question
  if (answersMap.get('E742C8A9-96D2-4707-B83C-2FF8DCA4A749') === 'NO') {
    errors.set(
      'E742C8A9-96D2-4707-B83C-2FF8DCA4A749',
      'You must have an Honorable, General, or General Under Honorable discharge to be eligible for disability compensation.',
    );
  }

  // These ids correspond to the entry and exit year question IDs for each
  // set of service period questions.
  [
    [
      '7E533253-A160-4027-B357-C8C6C3C032B0',
      '5EF68686-A351-4DDF-B45F-F79A3C9E1502',
    ],
    [
      '7FF1AE6A-BCFB-48CD-9474-01379BC61454',
      '43F5CCB4-EAA6-4B87-A1AB-38AD0949EACA',
    ],
    [
      'E35C6314-7EEF-44A5-9AD7-7BFF0051BF17',
      '38DD60F9-DE06-4557-81BE-F800AD57752E',
    ],
    [
      'B931BF07-FEEA-4131-933B-28A575F75993',
      '0E17665B-B850-49AB-9F69-BC195103DE09',
    ],
    [
      'CB364CE9-E3BF-48E0-81A6-87DADEC491F3',
      '3DAF171D-90BD-4FCD-A643-9F7770852601',
    ],
  ].forEach((servicePeriodYears) =>
    validateServicePeriodYears(
      answersMap,
      errors,
      servicePeriodYears[0],
      servicePeriodYears[1],
    ),
  );

  return errors;
};

export default validateAnswers;
