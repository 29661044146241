export const EZFields = [
  'activated_on_federal_orders',
  'activation_date',
  'additional_exposure_from',
  'additional_exposure_sources',
  'additional_exposure_sources_other_text',
  'additional_exposure_to',
  'address_change_effective_from',
  'address_change_effective_to',
  'anticipated_separation_date',
  'at_risk_living_situation',
  'at_risk_living_situation_other_text',
  'bdd_release_date',
  'branch_of_service',
  'city',
  'claim1',
  'claim10',
  'claim11',
  'claim12',
  'claim13',
  'claim14',
  'claim15',
  'claim2',
  'claim3',
  'claim4',
  'claim5',
  'claim6',
  'claim7',
  'claim8',
  'claim9',
  'claim_program',
  'claiming_toxic_exposure',
  'combat_zone',
  'component',
  'component_inactive',
  'component_reserves_or_national_guard',
  'confinement_date_from1',
  'confinement_date_from2',
  'confinement_date_to1',
  'confinement_date_to2',
  'country',
  'currently_at_risk_of_homelessness',
  'currently_homeless',
  'currently_receiving_retired_pay',
  'currently_va_employee',
  'date_of_birth',
  'disability_date1',
  'disability_date10',
  'disability_date11',
  'disability_date12',
  'disability_date13',
  'disability_date14',
  'disability_date15',
  'disability_date2',
  'disability_date3',
  'disability_date4',
  'disability_date5',
  'disability_date6',
  'disability_date7',
  'disability_date8',
  'disability_date9',
  'disability_relates1',
  'disability_relates10',
  'disability_relates11',
  'disability_relates12',
  'disability_relates13',
  'disability_relates14',
  'disability_relates15',
  'disability_relates2',
  'disability_relates3',
  'disability_relates4',
  'disability_relates5',
  'disability_relates6',
  'disability_relates7',
  'disability_relates8',
  'disability_relates9',
  'discharge_date1',
  'do_not_waive_inactive_pay',
  'do_not_waive_retired_pay',
  'due_to1',
  'due_to10',
  'due_to11',
  'due_to12',
  'due_to13',
  'due_to14',
  'due_to15',
  'due_to2',
  'due_to3',
  'due_to4',
  'due_to5',
  'due_to6',
  'due_to7',
  'due_to8',
  'due_to9',
  'electronic_correspondence',
  'email',
  'enlistment_date1',
  'first_name',
  'gulf_war_hazard_from',
  'gulf_war_hazard_to',
  'herbicide_from',
  'herbicide_locations_other',
  'herbicide_to',
  'homeless_living_situation',
  'homeless_living_situation_other_text',
  'international_phone_number',
  'last_name',
  'middle_initial',
  'mobile',
  'new_city',
  'new_country',
  'new_state',
  'new_street1',
  'new_street2',
  'new_zip',
  'point_of_contact_homeless',
  'point_of_contact_homeless_international_phone',
  'point_of_contact_homeless_phone',
  'pow',
  'prev_filed',
  'receiving_inactive_pay',
  'receiving_retired_pay_future',
  'receiving_retired_pay_future_text',
  'retired_amount',
  'retired_pay_branch_of_service',
  'retired_status',
  'separation_pay',
  'separation_pay_amount',
  'separation_pay_branch_of_service',
  'separation_pay_date',
  'separation_place',
  'served_in_gulf_war_hazard_locations',
  'served_in_herbicide_locations',
  'served_under_other_name',
  'service_entry_date',
  'service_exit_date',
  'service_name_list',
  'service_number',
  'ssn',
  'state',
  'street1',
  'street2',
  'term_of_service_from',
  'term_of_service_to',
  'toxic_exposure_additional_information',
  'treatment_date1',
  'treatment_date2',
  'treatment_date3',
  'treatment_date_not_available1',
  'treatment_date_not_available2',
  'treatment_date_not_available3',
  'treatment_facility1',
  'treatment_facility2',
  'treatment_facility3',
  'type_of_address_change',
  'unit_name_address',
  'unit_phone_number_string',
  'va_file_number',
  'zipcode',
];
