import { ArrowLeftCircleIcon } from '@heroicons/react/24/outline';
import {
  UIButton,
  UIContainer,
  UIHeader,
  UIParagraph,
} from '@team-and-tech/ui-components';
import { Link } from 'react-router-dom';
import { useDaVinciReportQuery } from '../api/queries/daVinciReport';
import UIQuestionnaireDashboardStatus from '../questionnaire-dashboard/UIQuestionnaireDashboardStatus';
import UIPotentialConditionsModal from './UIPotentialConditionsModal';
import UIServiceConnectedModal from './UIServiceConnectedModal';

const UIPlanSelection = () => {
  const { data: daVinciReport, isLoading, error } = useDaVinciReportQuery();

  if (isLoading) {
    return <UIQuestionnaireDashboardStatus loading={isLoading} />;
  }
  if (error) {
    return <UIQuestionnaireDashboardStatus error={error} />;
  }

  return (
    <UIContainer
      verticalAlignment="center"
      header={
        <div className="flex flex-row items-center">
          <Link to="../" className="block">
            <ArrowLeftCircleIcon className="text-primary h-8 w-8 mr-3" />
          </Link>
          <UIHeader level="1">Choose Your Plan</UIHeader>
        </div>
      }
    >
      <div className="space-y-5 sm:-mx-3">
        <UIParagraph>
          Now that you’ve got your Conditions Report, are you ready to pursue
          the disability benefits you qualify for? Trajector Medical may be able
          to help!
        </UIParagraph>
        <UIParagraph>
          We offer{' '}
          <span className="font-semibold">
            three different plans, including a FREE option.
          </span>
        </UIParagraph>
        {/* Tiers */}
        <div className="space-y-8 lg:mb-8 lg:grid lg:grid-cols-3 lg:gap-x-2 lg:space-y-0">
          {/* Toolbox */}
          <div className="relative flex flex-col rounded-2xl border border-gray-200 bg-white p-4 shadow-sm max-w-[370px] mx-auto">
            <div className="flex-1 space-y-6 flex flex-col">
              <div className="space-y-2">
                <p className="rounded-sm bg-gray-100 py-1 pt-1.5 px-4 text-xs font-semibold text-gray-800 text-center">
                  DIY
                </p>
                <UIHeader
                  level="3"
                  className="text-xl font-semibold text-gray-900 text-center"
                >
                  Veteran's Toolbox
                  <span className="align-super text-brand-red-300 uppercase font-bold text-xs ml-1">
                    BETA
                  </span>
                </UIHeader>
              </div>
              {/* Feature list */}
              <div className="space-y-2">
                <ul className="divide-y-2 text-gray-700">
                  <li className="pb-3">
                    DIY option for potential new conditions you've{' '}
                    <span className="font-semibold">
                      never filed for before
                    </span>
                  </li>
                  <li className="py-3">
                    <span className="font-semibold">Free</span> self-service
                    filing tools
                  </li>
                  <li className="py-3">
                    <span className="font-semibold">Free</span> education and
                    support
                  </li>
                  <li className="py-3">
                    Get started{' '}
                    <span className="font-semibold">immediately</span>
                  </li>
                </ul>
                <UIParagraph className="font-bold text-center">
                  This plan covers
                </UIParagraph>

                <UIPotentialConditionsModal />
              </div>

              {daVinciReport?.predicted &&
              daVinciReport?.predicted?.length > 0 ? (
                <Link to="../toolbox" className="block w-full">
                  <UIButton use="primary" className="w-full">
                    Learn More
                  </UIButton>
                </Link>
              ) : (
                <UIButton use="secondary" className="w-full" state="disabled">
                  No potential new conditions
                </UIButton>
              )}
            </div>
          </div>
          {/* Trj Legal */}
          <div className="relative flex flex-col rounded-2xl border border-gray-200 bg-white p-4 shadow-sm max-w-[370px] mx-auto">
            <div className="flex-1 space-y-6 flex flex-col">
              <div className="space-y-2">
                <p className="py-1 px-4 text-xs hidden md:block">&nbsp;</p>
                <UIHeader
                  level="3"
                  className="text-xl font-semibold text-gray-900 text-center"
                >
                  Lawyer in Your Corner
                </UIHeader>
              </div>
              {/* Feature list */}
              <div className="space-y-2">
                <ul className="divide-y-2 text-gray-700">
                  <li className="pb-3">
                    <span className="font-semibold">Free</span> claim filing
                    service
                  </li>
                  <li className="py-3">
                    VA-accredited lawyer representation for appeals
                  </li>
                  <li className="py-3">
                    Full audit of your VA decision and your{' '}
                    <span className="font-semibold">entire</span> claims file
                  </li>
                </ul>
                <UIParagraph className="font-bold text-center">
                  This plan covers
                </UIParagraph>
                <UIServiceConnectedModal />
                <UIPotentialConditionsModal />
              </div>

              <Link to="../legal" className="block w-full">
                <UIButton use="primary" className="w-full">
                  Learn More
                </UIButton>
              </Link>
            </div>
          </div>
          {/* Full-service */}
          <div className="relative flex flex-col rounded-2xl border border-gray-200 bg-white p-4 shadow-sm max-w-[370px] mx-auto">
            <div className="flex-1 space-y-6 flex flex-col">
              <div className="space-y-2">
                <p className="rounded-sm bg-brand-green-100 py-1 pt-1.5 px-4 text-xs font-semibold text-brand-green-400 text-center">
                  MOST POPULAR CHOICE
                </p>
                <UIHeader
                  level="3"
                  className="text-xl font-semibold text-gray-900 text-center"
                >
                  Full Service
                </UIHeader>
              </div>
              {/* Feature list */}
              <div className="space-y-2">
                <ul className="divide-y-2 text-gray-700">
                  <li className="pb-3">
                    Complete development of your medical evidence
                  </li>
                  <li className="py-3">
                    One-on-one phone consultations at key moments
                  </li>
                  <li className="py-3">Step-by-step service and support</li>
                  <li className="py-3">Full audit of your VA decision</li>
                </ul>
                <UIParagraph className="font-bold text-center">
                  This plan covers
                </UIParagraph>
                <UIServiceConnectedModal />
                <UIPotentialConditionsModal />
              </div>

              <Link to="../full-service" className="block w-full">
                <UIButton use="primary" className="w-full">
                  Learn More
                </UIButton>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </UIContainer>
  );
};
export default UIPlanSelection;
