import { UIParagraph } from '@team-and-tech/ui-components';

import { UIBadgeTypes } from '@team-and-tech/ui-components';
import { Link, LinkProps } from 'react-router-dom';
import { RefAttributes } from 'react';
import { Survey } from '@team-and-tech/trajector-cloud-api-client';
import { QuestionnaireCompletionStatus } from './getQuestionnaireCompletionStatus';

//TODO:  We'll enhance UIAnchor for consistent styles so we can wrap router links so we don't cause a full page refresh
export const UIRouterLink = (
  props: JSX.IntrinsicAttributes & LinkProps & RefAttributes<HTMLAnchorElement>,
) => {
  return (
    <Link
      {...props}
      className={`text-base text-blue-600 underline ${props.className}`}
    />
  );
};

export const getBadgeStatus = (
  surveyKey: Survey,
  questionnaireStatusMap: Map<Survey, QuestionnaireCompletionStatus>,
) => {
  const isBackground = surveyKey === Survey.Background;

  const backgroundComplete =
    questionnaireStatusMap.get('background') === 'complete';

  const surveyComplete = questionnaireStatusMap.get(surveyKey) === 'complete';
  const surveyInProgress =
    questionnaireStatusMap.get(surveyKey) === 'in_progress';

  // If this is for the background Badge, or the background is complete, follow normal
  // display determination
  if (isBackground || backgroundComplete) {
    if (surveyComplete) {
      return {
        SurveyComponent: UIParagraph,
        badgeType: UIBadgeTypes.SUCCESS,
        badgeText: 'Completed',
      };
    }
    if (surveyInProgress) {
      return {
        SurveyComponent: UIRouterLink,
        badgeType: UIBadgeTypes.DEFAULT,
        badgeText: 'In Progress',
      };
    }
    if (surveyComplete === false && surveyInProgress === false) {
      return {
        SurveyComponent: UIRouterLink,
        badgeType: UIBadgeTypes.INFO,
        badgeText: 'Not started',
      };
    }
  }
  // For all other questionnaires, pass no badge or link unless Background is complete
  return {
    SurveyComponent: UIParagraph,
    badgeType: null,
    badgeText: '',
  };
};
