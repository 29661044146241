import { Transition, Dialog } from '@headlessui/react';
import {
  ArrowLongRightIcon,
  DocumentDuplicateIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { UIHeader, UIParagraph, UISmall } from '@team-and-tech/ui-components';
import { Fragment, useState } from 'react';
import { useDaVinciReportQuery } from '../api/queries/daVinciReport';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

const UIServiceConnectedModal = () => {
  const [open, setOpen] = useState(false);
  const { data: daVinciReport, isLoading } = useDaVinciReportQuery();

  if (isLoading || typeof daVinciReport?.serviceConnected === 'undefined') {
    return (
      <>
        <UIParagraph>Filing for:</UIParagraph>
        <div className="bg-gray-100 p-2 rounded-md border border-1 border-gray-200 space-y-4">
          <div className="flex flex-row items-center space-x-2">
            <DocumentDuplicateIcon
              className="h-5 w-5 text-gray-600"
              aria-hidden="true"
            />
            <div>
              <UIParagraph className="!text-sm font-bold">
                Potential conditions
              </UIParagraph>
              <p className="!text-sm cursor-pointer">Show me</p>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <UIParagraph>Increases and appeals for:</UIParagraph>
      <div className="bg-blue-100 p-2 rounded-md border border-1 border-blue-200 space-y-4">
        <div className="flex flex-row items-center space-x-2">
          <DocumentDuplicateIcon
            className="h-5 w-5 text-blue-600"
            aria-hidden="true"
          />
          <div>
            <UIParagraph className="!text-sm font-bold">
              VA-Rated Disabilities
            </UIParagraph>
            {daVinciReport.serviceConnected.length > 0 ? (
              <p
                className="!text-sm underline cursor-pointer"
                onClick={() => setOpen(true)}
              >
                Show me
              </p>
            ) : (
              <UISmall className="!text-sm">
                <ExclamationTriangleIcon className="text-yellow-500 h-4 w-4 inline-block" />{' '}
                No VA-Rated Disabilities
              </UISmall>
            )}
          </div>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform sm:overflow-hidden sm:rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                  <div className="flex flex-row justify-end p-3">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 flex"
                      onClick={() => setOpen(false)}
                    >
                      <span className="">Close&nbsp;</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <div className="bg-blue-100 p-5 rounded-md border border-1 border-blue-200 space-y-4">
                    <div className="flex flex-row items-center space-x-2">
                      <DocumentDuplicateIcon
                        className="h-6 w-6 text-blue-600"
                        aria-hidden="true"
                      />
                      <UIHeader level="4" className="!text-lg">
                        Your VA-Rated Disabilities
                      </UIHeader>
                    </div>
                    <hr />
                    <ul className="space-y-2 sm:pl-4">
                      {daVinciReport.serviceConnected.map(
                        (claimText: string, idx) => (
                          <li className="flex items-center" key={idx}>
                            <ArrowLongRightIcon className="h-4 w-4 mr-3" />
                            <span>{claimText}</span>
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default UIServiceConnectedModal;
