import React from 'react';
import useCachedValue from './useCachedValue';
const CACHE_ID = '394CF594-A819-4789-B8A2-3498A13EFCC4';

const useCachedAnswersMap = () => {
  const { setValue, getValue } = useCachedValue();

  const handleSetValue = React.useCallback(
    (value: Map<string, any>) =>
      setValue(Array.from(value.entries()), CACHE_ID),
    [setValue],
  );

  const answersMap = new Map(
    (getValue(CACHE_ID) as [string, any][]) || undefined,
  );

  return {
    setMap: handleSetValue,
    answersMap,
  };
};

export default useCachedAnswersMap;
