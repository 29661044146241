import { UIButton } from '@team-and-tech/ui-components';

interface UIQuestionnaireButtonsProps {
  numPages: number;
  nextPage: () => void;
  prevPage: () => void;
  onSubmit: () => void;
  currentPage: number;
}

const UIQuestionnaireButtons = ({
  numPages,
  nextPage,
  prevPage,
  currentPage,
  onSubmit,
}: UIQuestionnaireButtonsProps) => {
  return (
    <div className="flex flex-row justify-between">
      {currentPage > 1 && (
        <UIButton use="secondary" onClick={() => prevPage()} state={'default'}>
          Back
        </UIButton>
      )}
      {currentPage === numPages ? (
        <UIButton
          use="primary"
          onClick={onSubmit}
          state="default"
          className="ml-auto"
        >
          Submit
        </UIButton>
      ) : (
        <UIButton
          use="primary"
          onClick={() => nextPage()}
          state="default"
          className="ml-auto"
        >
          Next
        </UIButton>
      )}
    </div>
  );
};

export default UIQuestionnaireButtons;
