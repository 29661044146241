import { Dispatch, SetStateAction } from 'react';
import { Question } from '../questions/types';
import { UICheckboxGroup } from '@team-and-tech/ui-components';

interface Props {
  answer: any[];
  question: Question;
  setAnswer: Dispatch<SetStateAction<any[]>>;
}

export default function UIAnswerCheckboxes({
  answer,
  question,
  setAnswer,
}: Props) {
  return (
    <UICheckboxGroup
      name={question.id}
      options={question.options}
      onChange={setAnswer}
      value={answer}
    />
  );
}
