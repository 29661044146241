import {
  UIButton,
  UIContainer,
  UIHeader,
  UIParagraph,
  useNotification,
} from '@team-and-tech/ui-components';
import {
  ChevronUpIcon,
  ArrowLeftCircleIcon,
} from '@heroicons/react/24/outline';
import { Link, useNavigate } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { useState } from 'react';
import UIComparisonModal from './UIComparisonModal';
import UIConfirmationModal from './UIConfirmationModal';
import { usePathSelectionSubmit } from '../api/mutations/pathSelectionSubmit';

const UIFullServiceDetails = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const navigate = useNavigate();
  const { createNotification } = useNotification();
  const { mutate: submitLead, isLoading: mutateIsLoading } =
    usePathSelectionSubmit('medical', {
      onSuccess: () => navigate('../full-service-success'),
      onError: () =>
        createNotification('Something went wrong, try again.', 'error'),
    });
  return (
    <>
      <UIContainer
        verticalAlignment="center"
        header={
          <div className="flex flex-row items-center">
            <Link to="../plans" className="block">
              <ArrowLeftCircleIcon className="text-primary h-8 w-8 mr-3" />
            </Link>
            <UIHeader level="1">Full-Service</UIHeader>
            <p className="ml-auto rounded-sm bg-brand-green-100 py-1 pt-1.5 px-3 text-xs font-semibold text-brand-green-400">
              MOST POPULAR CHOICE
            </p>
          </div>
        }
      >
        <div className="space-y-6">
          <UIParagraph className="my-3">
            Our most comprehensive and full-featured option.
          </UIParagraph>
          <div className="flex flex-col">
            <Disclosure defaultOpen>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`flex w-full px-4 py-3 text-left text-lg hover:bg-brand-blue-100 focus:outline-none focus-visible:ring focus-visible:ring-primary items-center ${
                      open ? 'bg-brand-blue-100' : ''
                    }`}
                  >
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-gray-500 mr-3`}
                    />
                    What Do I Get?
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-gray-700 pl-5 sm:pl-10 py-5">
                    <ul className="list-disc ml-3 space-y-1">
                      <li>
                        <span className="font-bold">
                          A full medical evidence package
                        </span>{' '}
                        detailing your service connections, your secondary
                        claims, your medical nexus, and more
                      </li>
                      <li>
                        <span className="font-bold">
                          A one-on-one phone consultation
                        </span>{' '}
                        with a licensed medical professional{' '}
                      </li>
                      <li>
                        <span className="font-bold">
                          Personal support by phone and email
                        </span>{' '}
                        throughout the process
                      </li>
                      <li>
                        <span className="font-bold">Referrals</span> to needed
                        medical services or professionals
                      </li>
                      <li>
                        <span className="font-bold">
                          Professional, compassionate help
                        </span>{' '}
                        with the VA’s required documents and processes
                      </li>
                      <li>
                        <span className="font-bold">
                          Simple, effective tools
                        </span>{' '}
                        to help you create and file your claims
                      </li>
                      <li>
                        <span className="font-bold">An in-depth audit</span> of
                        your VA decision
                      </li>
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`flex w-full px-4 py-3 text-left text-lg hover:bg-brand-blue-100 focus:outline-none focus-visible:ring focus-visible:ring-primary items-center ${
                      open ? 'bg-brand-blue-100' : 'border-t-2 border-blue-100'
                    }`}
                  >
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-gray-500 mr-3`}
                    />
                    What Do I Pay?
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-gray-700 pl-5 sm:pl-10 py-5">
                    <UIHeader level="4" className="sm:-ml-3 mb-2">
                      If I’m Approved:
                    </UIHeader>
                    <UIParagraph>
                      5 times the difference between your old monthly VA
                      disability check and your new monthly VA disability check.
                    </UIParagraph>
                    <UIParagraph>(Other fees may sometimes apply.)</UIParagraph>
                    <UIHeader level="4" className="sm:-ml-3 mb-2 mt-3">
                      If I’m Denied:
                    </UIHeader>
                    <UIParagraph>
                      Nothing! We’re so confident in this service that{' '}
                      <span className="font-bold">all the risk is on us</span>.
                    </UIParagraph>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`flex w-full px-4 py-3 text-left text-lg hover:bg-brand-blue-100 focus:outline-none focus-visible:ring focus-visible:ring-primary items-center ${
                      open ? 'bg-brand-blue-100' : 'border-t-2 border-blue-100'
                    }`}
                  >
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-gray-500 mr-3`}
                    />
                    How Long Will it Take?
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-gray-700 pl-5 sm:pl-10 py-5">
                    <ul className="list-disc ml-3 space-y-1">
                      <li>
                        You’ll schedule your one-on-one phone consultation right
                        away.
                      </li>
                      <li>
                        Then we’ll move quickly to collect every symptom, every
                        document, and every referral you need.
                      </li>
                      <li>
                        Next, we’ll build a detailed Medical Evidence Package
                        (your MEP) just for you.
                      </li>
                      <li>
                        After that, you’ll use your MEP to complete your forms
                        and file!
                      </li>
                    </ul>
                    {/* <UIHeader level="4" className="sm:-ml-3 mb-2 mt-3">
                      Approximate Time to Filing
                    </UIHeader>
                    <UIParagraph>XXXX weeks</UIParagraph> */}
                    {/* <UIHeader level="4" className="sm:-ml-3 mb-2 mt-3">
                      Approximate Time to Completion:
                    </UIHeader>
                    <UIParagraph>XXXX months</UIParagraph> */}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`flex w-full px-4 py-3 text-left text-lg hover:bg-brand-blue-100 focus:outline-none focus-visible:ring focus-visible:ring-primary items-center ${
                      open ? 'bg-brand-blue-100' : 'border-t-2 border-blue-100'
                    }`}
                  >
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-gray-500 mr-3`}
                    />
                    Is This Right for Me?
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-gray-700 pl-5 sm:pl-10 py-5">
                    <UIHeader level="4" className="sm:-ml-3 mb-2">
                      We recommend Full-Service to:
                    </UIHeader>
                    <ul className="list-disc ml-3 space-y-1">
                      <li>Veterans who aren’t sure what to claim</li>
                      <li>Veterans who are too busy to do this themselves</li>
                      <li>
                        Veterans who prefer phone assistance and personal help
                      </li>
                      <li>Veterans who hate dealing with the VA </li>
                      <li>Veterans with a complicated medical history </li>
                      <li>Veterans who are currently rated too low </li>
                      <li>Veterans who tried VSOs and weren’t satisfied</li>
                      <li>
                        Veterans who filed on their own and weren’t satisfied
                      </li>
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
          <UIButton use="primary" onClick={() => setConfirmationOpen(true)}>
            Get Started With This Plan
          </UIButton>
          <UIParagraph>
            Still Deciding?{' '}
            <span
              onClick={() => setModalOpen(true)}
              className="text-primary underline cursor-pointer"
            >
              Compare Plans
            </span>
          </UIParagraph>
        </div>
      </UIContainer>
      <UIComparisonModal open={modalOpen} setOpen={setModalOpen} />
      <UIConfirmationModal
        open={confirmationOpen}
        setOpen={setConfirmationOpen}
        callback={() => submitLead()}
        loading={mutateIsLoading}
        content={
          <>
            You chose: <span className="font-bold">Full Service</span>
          </>
        }
        title="Ready to get started with this plan?"
      />
    </>
  );
};
export default UIFullServiceDetails;
