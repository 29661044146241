import {
  UIButton,
  UIContainer,
  UIHeader,
  UIParagraph,
} from '@team-and-tech/ui-components';
import {
  DocumentDuplicateIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/24/outline';

import UIQuestionnaireDashboardStatus from '../questionnaire-dashboard/UIQuestionnaireDashboardStatus';
import { Link } from 'react-router-dom';
import { useDaVinciReportQuery } from '../api/queries/daVinciReport';
import { useSessionQuery } from '../api/queries/session';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import ReactGA from 'react-ga4';

const UIClaimsReport = () => {
  const { data: session } = useSessionQuery();
  const {
    data: daVinciReport,
    isLoading,
    error,
  } = useDaVinciReportQuery({
    onSuccess: (data) =>
      ReactGA.event('claims_report', {
        scope: 'user',
        predicted: data?.predicted.length || 0,
        serviceConnected: data?.serviceConnected.length || 0,
      }),
  });

  if (isLoading) {
    return <UIQuestionnaireDashboardStatus loading={isLoading} />;
  }
  if (error) {
    return <UIQuestionnaireDashboardStatus error={error} />;
  }

  if (typeof daVinciReport !== 'undefined') {
    if (
      daVinciReport.predicted.length === 0 &&
      daVinciReport.serviceConnected.length === 0
    ) {
      return (
        <UIContainer verticalAlignment="center">
          <div className="space-y-4 text-center">
            <div className="mx-auto flex items-center justify-center h-20 w-20 rounded-full bg-yellow-100 my-3">
              <InformationCircleIcon
                className="h-14 w-14 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <UIHeader level="1">
              No potential new conditions or VA-rated disabilities identified
            </UIHeader>
            <UIParagraph className="mb-3">
              Unfortunately, given the information you provided in your forms,
              we could not identify any potential new conditions or existing
              VA-rated disabilities.
            </UIParagraph>
            <UIParagraph className="mb-3">
              If you think this isn't correct, you can contact us for help via
              the information at the top of the screen.
            </UIParagraph>
          </div>
        </UIContainer>
      );
    }

    return (
      <UIContainer
        verticalAlignment="center"
        header={<UIHeader level="1">Conditions Report</UIHeader>}
      >
        <div className="space-y-4">
          {daVinciReport.serviceConnected.length > 0 ? (
            <>
              <UIParagraph className="my-3">
                These are your reported VA-rated disabilities:
              </UIParagraph>
              <div className="bg-blue-100 p-5 rounded-md border border-1 border-blue-200 space-y-4">
                <div className="flex flex-row items-center space-x-2">
                  <DocumentDuplicateIcon
                    className="h-6 w-6 text-blue-600"
                    aria-hidden="true"
                  />
                  <UIHeader level="4" className="!text-lg">
                    Current VA-Rated Disabilities
                  </UIHeader>
                </div>
                <hr />
                <ul className="space-y-2 sm:pl-4">
                  {daVinciReport.serviceConnected.map(
                    (claimText: string, idx) => (
                      <li className="flex items-center" key={idx}>
                        <ArrowLongRightIcon className="h-4 w-4 mr-3" />
                        <span>{claimText}</span>
                      </li>
                    ),
                  )}
                </ul>
              </div>
            </>
          ) : (
            <>
              <UIParagraph className="my-3">
                Our system was unable to identify any VA-rated disabilities from
                your inputs
              </UIParagraph>
              <div className="bg-blue-100 p-5 rounded-md border border-1 border-blue-200 space-y-4">
                <div className="flex flex-row items-center space-x-2">
                  <DocumentDuplicateIcon
                    className="h-6 w-6 text-blue-600"
                    aria-hidden="true"
                  />
                  <UIHeader level="4" className="!text-lg">
                    No VA-Rated Disabilities
                  </UIHeader>
                </div>
              </div>
            </>
          )}
          {daVinciReport.predicted.length > 0 ? (
            <>
              <UIHeader level="2" className="mb-4">
                Congratulations, {session?.user?.first_name}!
              </UIHeader>
              <UIParagraph className="my-3">
                We identified these potential NEW VA-ratable health conditions
                for you:
              </UIParagraph>
              <div className="bg-green-100 p-5 rounded-md border border-1 border-green-200 space-y-4">
                <div className="flex flex-row items-center space-x-2">
                  <DocumentDuplicateIcon
                    className="h-6 w-6 text-green-600"
                    aria-hidden="true"
                  />
                  <UIHeader level="4" className="!text-lg">
                    Your Potential New VA-Ratable Conditions
                  </UIHeader>
                </div>
                <hr />
                <ul className="space-y-2 sm:pl-4">
                  {daVinciReport.predicted.map((claimText: string, idx) => (
                    <li className="flex items-center" key={idx}>
                      <ArrowLongRightIcon className="h-4 w-4 mr-3" />
                      <span>{claimText}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          ) : (
            <>
              <UIParagraph className="my-3">
                Our system was unable to identify any potential new VA-ratable
                conditions for you
              </UIParagraph>
              <div className="bg-green-100 p-5 rounded-md border border-1 border-green-200 space-y-4">
                <div className="flex flex-row items-center space-x-2">
                  <DocumentDuplicateIcon
                    className="h-6 w-6 text-green-600"
                    aria-hidden="true"
                  />
                  <UIHeader level="4" className="!text-lg">
                    No Potential New VA-Ratable Conditions
                  </UIHeader>
                </div>
              </div>
            </>
          )}

          <div className="flex flex-col items-center">
            <Link to="plans">
              <UIButton use="primary">See How We Can Help</UIButton>
            </Link>
          </div>
        </div>
      </UIContainer>
    );
  } else {
    return <UIParagraph>Unexpected Error.</UIParagraph>;
  }
};
export default UIClaimsReport;
