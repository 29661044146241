import { CheckIcon } from '@heroicons/react/24/outline';
import {
  UIHeader,
  UIContainer,
  UIParagraph,
} from '@team-and-tech/ui-components';

const UILegalSuccess = () => {
  return (
    <UIContainer verticalAlignment="center">
      <div data-testid="success-page" className="text-center">
        <div className="mx-auto flex items-center justify-center h-20 w-20 rounded-full bg-green-100 my-3">
          <CheckIcon className="h-10 w-10 text-green-600" aria-hidden="true" />
        </div>
        <UIHeader level="3" className="mb-4">
          Great! You just selected to receive help from one of our expert
          lawyers.
        </UIHeader>
        <UIParagraph>One of our legal experts will reach out soon.</UIParagraph>
      </div>
    </UIContainer>
  );
};

export default UILegalSuccess;
