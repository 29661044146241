import { UINotifications } from '@team-and-tech/ui-components';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar';

const Layout = () => {
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      <Navbar />
      <main className="flex flex-grow w-full">
        <Outlet />
      </main>
      <div className="z-[9999]">
        <UINotifications />
      </div>
    </div>
  );
};

export default Layout;
