export const GENDER_ANSWERS = [
  ['CCC96848-9AA4-4EBF-8EC5-3445D8562909', ['NONE_OF_THESE_APPLY_TO_ME']],
  ['B7CBA059-32D4-427B-9E8E-23241BE2363B', ['INABILITY_TO_ACHIEVE_ORGASM']],
  [
    'E5DAAEFD-069C-4FB7-9EE8-C50C28D205EA',
    [
      'I_NEVER_APPLIED_FOR_THIS_BUT_I_HAD_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
      'I_NEVER_APPLIED_FOR_THIS_AND_I_DID_NOT_HAVE_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
    ],
  ],
];
