import { UIAlert, UIAnchor, UIParagraph } from '@team-and-tech/ui-components';
import { Question } from '../types';
import useStore from '../../app/store';

interface ActiveDutyFooterProps {
  question: Question;
  'data-testid'?: string;
}

const ActiveDutyFooter = ({
  question,
  'data-testid': testId,
}: ActiveDutyFooterProps) => {
  const { answers: answersStore } = useStore();
  const activeDutyAnswer = answersStore.get(question.id);

  if (activeDutyAnswer === 'NO') {
    return (
      <div data-testid={testId} className="flex flex-col space-y-4">
        <UIAlert
          title="Do not proceed forward in this questionnaire at this current time."
          contentNode="Under current VA Disability law, you must have an Honorable, General,
          or General Under Honorable discharge to be eligible for disability
          compensation."
          severity="error"
        />
        <UIParagraph>
          If your discharge was other than Honorable or Dishonorable you can
          apply for a discharge upgrade at:
          <br />{' '}
          <UIAnchor href="https://www.va.gov/discharge-upgrade-instructions/">
            How To Apply For A Discharge Upgrade Page
          </UIAnchor>
        </UIParagraph>
        <UIParagraph>
          If you are successful in upgrading your discharge type, please return
          to this page and complete our questionnaire to discover all the
          benefits you medically and ethically qualify for as a proud US
          military veteran.
        </UIParagraph>
      </div>
    );
  } else {
    return null;
  }
};

export default ActiveDutyFooter;
