export const BACKGROUND_ANSWERS = [
  ['DDA7655A-CEDF-4806-B747-F88DBFA5395E', 'John Sample-User'],
  ['5C7D4EFB-87FF-44B9-9D54-80755211DA91', '7874939139'],
  ['AFA9C8CB-C959-4D47-BCD7-A63BA6724A5E', 'sample.user+1235054@trajector.com'],
  ['82B1A3D8-FC3C-4443-BED9-36DC3F91CA3C', '1988-02-01'],
  ['E742C8A9-96D2-4707-B83C-2FF8DCA4A749', 'YES'],
  ['EF1925D3-2F1E-4EA9-B282-7E7F5C1FA879', '1234'],
  ['87F3CADE-228A-44B7-AB92-596132AEC1E9', 'FEMALE'],
  ['54216876-D5E3-4D71-B14C-31D4C950DAB8', 23],
  ['569775B5-0E85-4D76-A1DE-8779AB27D69D', 23],
  ['F520DD4F-5248-424E-998B-A5355BA31354', 23],
  ['EE72DB0C-3489-4368-BD8D-C9BA435D0855', 23],
  [
    '2707B038-0E70-43BC-B33B-0620A0EB66B0',
    ['branch_of_service_air_force', 'branch_of_service_coast_guard'],
  ],
  ['115D2D66-56C4-4A48-979C-2BB3940AD1BB', ['component_reserves']],
  [
    '8DE1FF35-A853-4FE5-AE5D-9052FE071FA6',
    [
      'SERVICE_IN_SW_ASIA_THEATER_OF_OPERATION_BETWEEN_AUGUST_2_1990_AND_PRESENT_SUCH_AS_AFGHANISTAN_IRAQ_KUWAIT',
      'KOREAN_DEMILITARIZED_ZONE_BETWEEN_SEPTEMBER_1_1967_AND_AUGUST_31_1971',
    ],
  ],
  ['37414175-1255-477A-9139-3284C129EC8B', 'NO'],
  ['7FC62019-6E4F-4FE9-9DE5-DECEF2F6E5AA', 'YES'],
  ['95865F66-4D97-4EDA-B4F2-C23DBBE75436', 'YES'],
  ['975AA66E-0B52-416C-8638-21EF21F8816F', '10'],
  ['A079FAF4-8915-4617-B560-2A66E588AAD0', 'YES_I_HAVE_MOST'],
  [
    '6D9B46DF-56D6-4B0F-A30B-BAE6EB1E394E',
    ['BURN_PITS', 'LOUD_NOISES_DURING_ACTIVE_DUTY'],
  ],
  [
    '3C4F683C-AC7A-4919-A336-9B29938C846A',
    [
      'STEROIDS_PREDNISONE',
      'MUSCLE_RELAXANTS',
      'NONSTEROIDAL_ANTIINFLAMMATORY_DRUGS_NSAIDS_MOTRIN_IBUPROFEN_MELOXICAM_ALEVE',
      'HYDROCHLOROTHIAZIDE_WATER_PILL_DIURETIC_FOR_HIGH_BLOOD_PRESSURE',
    ],
  ],
  ['9DABDD5D-44AD-439D-BD4B-E9A008C498F8', 'YES'],
  ['815891C3-B1D1-465C-BE9A-D2117C6FC7DF', 'NO'],
  ['6A8E1740-429D-43BC-9397-F23AD3F02789', 'NO'],
  [
    '14CBE901-40E9-49F5-A07A-D590E896C9CB',
    'YES_MY_SERVICECONNECTED_DISABILITIES_IMPACT_MY_DAILY_LIFE',
  ],
  ['d7579f50-dd41-11ed-ad0e-29e11a56e736', 'agreed_to_davinci_tos'],
];
