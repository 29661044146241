import { User } from '@team-and-tech/trajector-cloud-api-client';

export type AnswerOption = {
  label: string;
  value: Answer['value'];
};

export enum InputType {
  NUMBER = 'NUMBER',
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  SINGLE_LINE = 'SINGLE_LINE',
  DROPDOWN = 'DROPDOWN',
  RADIO = 'RADIO',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  NAME = 'NAME',
  CURRENCY = 'CURRENCY',
  MULTI_LINE = 'MULTI_LINE',
  SINGLE_SELECT = 'SINGLE_SELECT',
  SSN = 'SSN',
}

type DisplayRuleEquals = {
  questionId: string;
  condition: 'equals';
  value: string;
};

type DisplayRuleExists = {
  questionId: string;
  condition: 'exists';
};

type DisplayRuleContains = {
  questionId: string;
  condition: 'contains';
  value: string[];
};

export type DisplayRule =
  | DisplayRuleContains
  | DisplayRuleEquals
  | DisplayRuleExists;

export type Format = 'zipCode' | 'state';

export type Question = {
  description?: string;
  id: string;
  input_type: InputType;
  isRequired?: boolean;
  displayRules?: DisplayRule[];
  text: string;
  options: AnswerOption[];
  questionFooter?: string;
  questionHeader?: string;
  zoho_link_name?: string;
  zoho_page_id?: string;
  af_key?: string;
  maxLength?: number;
  minLength?: number;
  length?: number;
  format?: string;
};

export type Answer = {
  __typename?: 'Answer';
  created_at: string;
  questionId: Question['id'];
  updated_at: string;
  user?: User;
  value?: any;
};
