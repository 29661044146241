import { ReactNode } from 'react';
import { UIContainer, UIHeader } from '@team-and-tech/ui-components';
import { UIParagraph } from '@team-and-tech/ui-components';

interface UIQuestionnaireLayoutProps {
  buttons?: ReactNode;
  children: ReactNode;
  description?: string;
  header?: ReactNode;
}

const UIQuestionnaireLayout = ({
  buttons,
  children,
  description,
  header,
}: UIQuestionnaireLayoutProps) => {
  return (
    <UIContainer
      header={
        (description || header) && (
          <>
            {header &&
              (typeof header === 'string' ? (
                <>
                  <UIHeader className={description && 'mb-1'} level="1">
                    {header}
                  </UIHeader>
                </>
              ) : (
                header
              ))}
            {description && (
              <UIParagraph className="!text-gray-500">
                {description}
              </UIParagraph>
            )}
          </>
        )
      }
      footer={buttons}
    >
      <div>{children}</div>
    </UIContainer>
  );
};

export default UIQuestionnaireLayout;
