import { Dispatch, SetStateAction } from 'react';
import { Question } from '../questions/types';
import { NumericFormat } from 'react-number-format';
import { UITextInput } from '@team-and-tech/ui-components';

interface Props {
  answer?: number;
  question: Question;
  setAnswer: Dispatch<SetStateAction<any>>;
  onBlur?: (e: any) => void;
}

export default function UIAnswerCurrency({
  answer,
  question,
  onBlur,
  setAnswer,
}: Props) {
  // Value passed to API in cents, collected from input in whole dollars.
  return (
    <NumericFormat
      data-testid={question.id}
      name={question.id}
      onBlur={onBlur}
      allowNegative={false}
      onValueChange={({ value }) => setAnswer(Number.parseInt(value, 10) * 100)}
      value={answer ? answer / 100 : ''}
      customInput={UITextInput}
      prefix="$ "
      placeholder="$"
      decimalScale={2}
      thousandSeparator=","
      className="max-w-xs"
    />
  );
}
