import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import useSessionQuery from '../queries/session';
import useStore from '../../app/store';
import { mapAnswersToPayload } from './submitAnswers';
import { getClient } from '../client';

export const usePathSelectionSubmit = (
  path: 'legal' | 'medical' | 'toolbox',
  options?: UseMutationOptions,
) => {
  const { data: session } = useSessionQuery();
  const { token } = useStore();
  const answersWithPathSelection = new Map().set(
    '66e4e291-307c-4914-bd57-0314c3eebf24',
    `${path}_path_selected`,
  );
  const client = getClient();

  return useMutation(
    () =>
      client.createUserAnswers(
        session?.user.user_id || '',
        mapAnswersToPayload(answersWithPathSelection),
        {
          headers: {
            'X-Trajector-Invite': token,
          },
        },
      ),
    options,
  );
};
