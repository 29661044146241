import { UITextInput } from '@team-and-tech/ui-components';
import { Dispatch, SetStateAction } from 'react';
import { Question } from '../questions/types';
interface Props {
  answer: any;
  question: Question;
  setAnswer: Dispatch<SetStateAction<any>>;
  onBlur?: (e: any) => void;
}

export default function UIAnswerNumber({
  answer = '',
  question,
  onBlur,
  setAnswer,
}: Props) {
  return (
    <UITextInput
      name={question.id}
      data-testid={question.id}
      className="max-w-xs"
      onChange={(e) =>
        setAnswer(
          !!e.target.value.replace(/\D/g, '')
            ? Number.parseInt(e.target.value.replace(/\D/g, ''), 10)
            : '',
        )
      }
      value={answer?.toString()}
      onBlur={onBlur}
    />
  );
}
