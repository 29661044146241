export const MUSCULOSKELETAL_ANSWERS = [
  ['7A13B376-AAA3-46CC-AE38-D5F856411232', ['ANKLE_PROBLEM_LEFT']],
  [
    '33103F06-2DF8-485B-B8A7-0344FFE2C958',
    [
      'I_NEVER_APPLIED_FOR_THIS_BUT_I_HAD_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
      'I_NEVER_APPLIED_FOR_THIS_AND_I_DID_NOT_HAVE_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
    ],
  ],
  ['EADEB460-67E7-409B-B7F4-1D86826237A7', 'YES'],
  ['156E5D68-2AD9-4BF3-A5A5-6AF3996DF442', ['ELBOW_PROBLEM_LEFT']],
  [
    '94B99091-9A44-47C1-8155-3F39160CBEEF',
    [
      'I_NEVER_APPLIED_FOR_THIS_AND_I_DID_NOT_HAVE_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
      'I_NEVER_APPLIED_FOR_THIS_BUT_I_HAD_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
    ],
  ],
  ['83A6F09A-62EF-4E13-A707-AE71E4B359DE', 'YES'],
  [
    '1A378708-7212-49EF-83AD-7BDDFE735E7C',
    ['STRAIN_OR_CHRONIC_PAIN_OF_YOUR_LOWER_BACK_LUMBAR_SPINE'],
  ],
  [
    '1016F0C8-75E2-439A-B337-AA927010C2FD',
    [
      'I_NEVER_APPLIED_FOR_THIS_AND_I_DID_NOT_HAVE_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
      'I_NEVER_APPLIED_FOR_THIS_BUT_I_HAD_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
    ],
  ],
  ['97A91145-61FE-45A2-97F2-354308C23D0F', 'YES'],
];
