import { Transition, Dialog } from '@headlessui/react';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useDaVinciReportQuery } from '../api/queries/daVinciReport';

interface UIComparisonModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const PLANS = [
  {
    description: 'Data-driven analysis of your symptoms and medical history',
    fs: true,
    l: true,
    vt: true,
  },
  {
    description: 'Free DIY tools to help you file',
    fs: true,
    l: true,
    vt: true,
  },
  {
    description: 'Complete audit of your VA claim decisions',
    fs: true,
    l: true,
    vt: false,
  },
  {
    description: 'On-call support and assistance',
    fs: true,
    l: false,
    vt: false,
  },
  {
    description: 'Complete medical evidence package fully prepared for you',
    fs: true,
    l: false,
    vt: false,
  },
  {
    description: 'One-on-one phone consults for key steps',
    fs: true,
    l: false,
    vt: false,
  },
  {
    description: 'Free, professional claim filing service',
    fs: false,
    l: true,
    vt: false,
  },
  {
    description: 'VA-accredited lawyers on call for appeals',
    fs: false,
    l: true,
    vt: false,
  },
];

const UIComparisonModal = ({ open, setOpen }: UIComparisonModalProps) => {
  const { data: daVinciReport } = useDaVinciReportQuery();
  const noPotentialConditions =
    typeof daVinciReport?.predicted === 'undefined'
      ? true
      : daVinciReport.predicted.length === 0;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform sm:overflow-hidden sm:rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                <div className="flex flex-row justify-end p-3">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 flex"
                    onClick={() => setOpen(false)}
                  >
                    <span className="">Close&nbsp;</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="relative min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50 sticky left-0 top-0">
                      <tr className="divide-x divide-gray-200">
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-4 text-left text-xs sm:text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Feature
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-left text-xs sm:text-sm text-gray-900"
                        >
                          Full-Service
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-left text-xs sm:text-sm text-gray-900"
                        >
                          Lawyer In Your Corner
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-4 text-left text-xs sm:text-sm text-gray-900 sm:pr-6"
                        >
                          Free Vet Tools
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {PLANS.map((plan) => (
                        <tr
                          key={plan.description}
                          className="divide-x divide-gray-200"
                        >
                          <td className="py-4 pl-4 pr-4 text-xs sm:text-sm font-medium text-gray-900 sm:pl-6">
                            {plan.description}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-4 text-xs sm:text-sm font-medium text-gray-900 sm:pl-6">
                            {plan.fs ? (
                              <CheckCircleIcon className="h-5 w-5 text-green-700" />
                            ) : null}
                          </td>
                          <td className="whitespace-nowrap p-4 text-xs sm:text-sm text-gray-500">
                            {plan.l ? (
                              <CheckCircleIcon className="h-5 w-5 text-green-700" />
                            ) : null}
                          </td>
                          <td className="whitespace-nowrap p-4 text-xs sm:text-sm text-gray-500">
                            {plan.vt ? (
                              <CheckCircleIcon className="h-5 w-5 text-green-700" />
                            ) : null}
                          </td>
                        </tr>
                      ))}
                      <tr className="divide-x divide-gray-200">
                        <td></td>
                        <td className="text-xs sm:text-sm text-center p-2">
                          <Link
                            className="text-primary underline cursor-pointer"
                            onClick={() => setOpen(false)}
                            to="../full-service"
                          >
                            Learn More
                          </Link>
                        </td>
                        <td className="text-xs sm:text-sm text-center p-2">
                          <Link
                            className="text-primary underline cursor-pointer"
                            onClick={() => setOpen(false)}
                            to="../legal"
                          >
                            Learn More
                          </Link>
                        </td>
                        <td className="text-xs sm:text-sm text-center p-2">
                          {noPotentialConditions ? (
                            <>No Potential New Conditions</>
                          ) : (
                            <Link
                              aria-disabled={noPotentialConditions}
                              className="text-primary underline cursor-pointer"
                              onClick={() => setOpen(false)}
                              to="../toolbox"
                            >
                              Learn More
                            </Link>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UIComparisonModal;
