import useCachedValue from './useCachedValue';

const TOKEN_CACHE_ID = '93c9e0ef-2aa2-499d-8285-c2d83afcee44';

const useCachedToken = () => {
  const { setValue, getValue } = useCachedValue<string>();

  const handleSetValue = (value: string) => setValue(value, TOKEN_CACHE_ID);

  const token = getValue(TOKEN_CACHE_ID) || '';

  return {
    setToken: handleSetValue,
    token,
  };
};

export default useCachedToken;
