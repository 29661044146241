export const GI_SKIN_ANSWERS = [
  [
    '1137225A-55C4-49E3-9D1D-440EA53FEB71',
    ['GASTROESOPHAGEAL_REFLUX_DISEASE_GERD_REFLUX_DISEASE_OR_HEARTBURN'],
  ],
  [
    'C9CFB86B-A830-47D5-98C4-2D9BFFF3AFED',
    [
      'I_NEVER_APPLIED_FOR_THIS_AND_I_DID_NOT_HAVE_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
      'I_NEVER_APPLIED_FOR_THIS_BUT_I_HAD_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
    ],
  ],
  ['2AA517D4-4B87-47E9-9C03-655FE5E1E218', ['PORPHYRIA_CUTANEA_TARDA_PCT']],
  [
    '80A8F223-3D20-499A-93CD-7961BDAA01C8',
    [
      'I_NEVER_APPLIED_FOR_THIS_AND_I_DID_NOT_HAVE_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
      'I_NEVER_APPLIED_FOR_THIS_BUT_I_HAD_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
    ],
  ],
];
