import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useStore from './store';

export function RequireAuthToken({
  children,
  redirect,
}: {
  children: JSX.Element;
  redirect: string;
}) {
  const navigate = useNavigate();
  const { token } = useStore();
   useEffect(() => {
    if (!token) {
      navigate(redirect);
    }
  }, [token, navigate, redirect]);

  if (!token) {
    return null;
  }

  return children;
}
