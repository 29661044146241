import { Survey } from '../questionnaires/types';
import { NotificationProvider } from '@team-and-tech/ui-components';
import { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';
import UIClaimsReport from '../plan-selection/UIClaimsReport';
import UIFullServiceDetails from '../plan-selection/UIFullServiceDetails';
import UIFullServiceSuccess from '../plan-selection/UIFullServiceSuccess';
import UILegalDetails from '../plan-selection/UILegalDetails';
import UILegalSuccess from '../plan-selection/UILegalSuccess';
import UIPlanSelection from '../plan-selection/UIPlanSelection';
import UIToolboxDetails from '../plan-selection/UIToolboxDetails';
import UIToolboxStart from '../plan-selection/UIToolboxStart';
import UIToolboxSubmit from '../plan-selection/UIToolboxSubmit';
import UIQuestionnaire from '../questionnaires/components/UIQuestionnaire';
import UIQuestionnaireSuccessPage from '../questionnaires/components/UIQuestionnaireSuccessPage';
import AnswersCache from '../sessionCache/AnswersCache';
import GenerateInvite from './GenerateInvite';
import InviteError from './InviteError';
import Layout from './Layout';
import NoInviteLink from './NoInviteLink';
import { RequireAuthToken } from './RequireAuthToken';
import ResolveSlugAndMountVars from './ResolveSlugAndMountVars';
import { StoreProvider } from './store';
import UIQuestionnaireSelect from '../questionnaire-dashboard/UIQuestionnaireSelect';
import UIQuestionnaireDashboard from '../questionnaire-dashboard/UIQuestionnaireDashboard';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Entrypoint = () => {
  const { slug } = useParams();
  if (slug) {
    return <ResolveSlugAndMountVars slug={slug} />;
  }
  return <Navigate to="/" />;
};

function App() {
  return (
    <StoreProvider>
      <NotificationProvider>
        <ScrollToTop />
        <AnswersCache />
        <Routes>
          <Route element={<Layout />}>
            <Route path="*" element={<NoInviteLink />} />
            <Route path="generate" element={<GenerateInvite />} />
          </Route>
          {/* NOTE the existence of /invite in the url is necessary to ensure that Google analytics catches and filters out the slug information from the data it captures. If you modify the
          url structure make sure you modify the GA page_view events to prevent PII leaks */}
          <Route element={<Layout />} path="invite">
            <Route path=":slug" element={<Entrypoint />}>
              <Route index element={<UIQuestionnaireDashboard />} />
            </Route>
            <Route path="error" element={<InviteError />} />
          </Route>
          <Route
            element={
              <RequireAuthToken redirect="/invite/error">
                <Layout />
              </RequireAuthToken>
            }
            path="claims-report"
          >
            <Route index element={<UIClaimsReport />} />
            <Route path="plans" element={<UIPlanSelection />} />
            <Route path="legal" element={<UILegalDetails />} />
            <Route path="legal-success" element={<UILegalSuccess />} />
            <Route path="full-service" element={<UIFullServiceDetails />} />
            <Route
              path="full-service-success"
              element={<UIFullServiceSuccess />}
            />
            <Route path="toolbox" element={<UIToolboxDetails />} />
            <Route path="toolbox-start" element={<UIToolboxStart />} />
            <Route
              path={`toolbox-form`}
              element={<UIQuestionnaire id={'toolbox'} />}
            />
            <Route path="toolbox-submit" element={<UIToolboxSubmit />} />
          </Route>

          <Route
            path="questionnaire"
            element={
              <RequireAuthToken redirect="/invite/error">
                <Layout />
              </RequireAuthToken>
            }
          >
            <Route
              path={`${Survey.Background}`}
              element={<UIQuestionnaire id={Survey.Background} />}
            />
            <Route
              path={`${Survey.CardiacRespiratory}`}
              element={<UIQuestionnaire id={Survey.CardiacRespiratory} />}
            />
            <Route
              path={`${Survey.EndocrineImmune}`}
              element={<UIQuestionnaire id={Survey.EndocrineImmune} />}
            />
            <Route
              path={`${Survey.Gender}`}
              element={<UIQuestionnaire id={Survey.Gender} />}
            />
            <Route
              path={`${Survey.GiSkin}`}
              element={<UIQuestionnaire id={Survey.GiSkin} />}
            />
            <Route
              path={`${Survey.Musculoskeletal}`}
              element={<UIQuestionnaire id={Survey.Musculoskeletal} />}
            />
            <Route
              path={`${Survey.NervousCancer}`}
              element={<UIQuestionnaire id={Survey.NervousCancer} />}
            />
            <Route
              path={`${Survey.PsychEnt}`}
              element={<UIQuestionnaire id={Survey.PsychEnt} />}
            />

            <Route path="success" element={<UIQuestionnaireSuccessPage />} />
            <Route path="select" element={<UIQuestionnaireSelect />} />
          </Route>
        </Routes>
      </NotificationProvider>
    </StoreProvider>
  );
}

export default App;
