import { UIAlert } from '@team-and-tech/ui-components';

const AuthPhraseFooter = () => {
  return (
    <>
      <UIAlert
        contentNode="If you have medical conditions that qualify, you may be eligible for VA disability benefits. To continue with this form, please click the 'Next' button. Throughout the process, different questions may or may not generate based on your answers."
        severity="info"
      />
    </>
  );
};

export default AuthPhraseFooter;
