import { Survey } from '../questionnaires/types';

const QuestionnaireIds = {
  BackgroundId: 'e05a0217-7a29-465c-b27d-12d9ca29a532',
  CardiacRespiratoryId: '6c0d6dca-ca42-41de-92bc-0835db264f74',
  EndocrineImmuneId: 'f6dae4bb-9287-4385-b321-ff925d1b7d5e',
  GenderId: '4e8d6e91-a054-4c17-98e9-4b09145cac24',
  GiSkinId: '51675d75-68a6-4057-b3d1-330a6ecf3f08',
  MusculoskeletalId: '70f44e3c-1a0a-4a23-ac62-1ae043872588',
  NervousCancerId: 'a6c9feac-e7a9-461b-bd83-8521700f6e68',
  PsychEntId: '1472e721-75ed-454e-8999-6e79ad48be03',
} as const;

const QuestionnaireToIdMap = new Map<Survey, string>([
  [Survey.Background, QuestionnaireIds.BackgroundId],
  [Survey.CardiacRespiratory, QuestionnaireIds.CardiacRespiratoryId],
  [Survey.EndocrineImmune, QuestionnaireIds.EndocrineImmuneId],
  [Survey.Gender, QuestionnaireIds.GenderId],
  [Survey.GiSkin, QuestionnaireIds.GiSkinId],
  [Survey.Musculoskeletal, QuestionnaireIds.MusculoskeletalId],
  [Survey.NervousCancer, QuestionnaireIds.NervousCancerId],
  [Survey.PsychEnt, QuestionnaireIds.PsychEntId],
]);

const IdToQuestionnaireMap = new Map<string, Survey>(
  Array.from(QuestionnaireToIdMap, ([key, value]) => [value, key]),
);

const Conditions = {
  Psychological: 'Psychological Conditions',
  EarNoseThroat: 'Ear, Nose, Throat Conditions',
  Musculoskeletal: 'Musculoskeletal Conditions',
  Kidney: 'Kidney and Urinary Conditions',
  Endocrine: 'Endocrine Conditions',
  NervousSystem: 'Nervous System Conditions',
  Cancers: 'Cancers',
  GenderSpecific: 'Gender-Specific Conditions',
  Gastrointestinal: 'Gastrointestinal Conditions',
  Skin: 'Skin Conditions',
  Cardiac: 'Cardiac Conditions',
  Respiratory: 'Respiratory Conditions',
  ImmuneSystem: 'Immune System Conditions',
} as const;

type ConditionKeys = keyof typeof Conditions;

const ConditionToSurveyMap: Record<ConditionKeys, Survey> = {
  Psychological: Survey.PsychEnt,
  EarNoseThroat: Survey.PsychEnt,
  Musculoskeletal: Survey.Musculoskeletal,
  Kidney: Survey.EndocrineImmune,
  Endocrine: Survey.EndocrineImmune,
  NervousSystem: Survey.NervousCancer,
  Cancers: Survey.NervousCancer,
  GenderSpecific: Survey.Gender,
  Gastrointestinal: Survey.GiSkin,
  Skin: Survey.GiSkin,
  Cardiac: Survey.CardiacRespiratory,
  Respiratory: Survey.CardiacRespiratory,
  ImmuneSystem: Survey.EndocrineImmune,
};

export type { ConditionKeys };

export {
  QuestionnaireIds,
  QuestionnaireToIdMap,
  IdToQuestionnaireMap,
  ConditionToSurveyMap,
  Conditions,
};
