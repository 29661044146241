import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import {
  UIContainer,
  UIHeader,
  UIParagraph,
  UIButton,
  UISmall,
  UIStrong,
} from '@team-and-tech/ui-components';
import UIQuestionnaireDashboardStatus from '../questionnaire-dashboard/UIQuestionnaireDashboardStatus';
import { useDaVinciReportQuery } from '../api/queries/daVinciReport';
import { Link } from 'react-router-dom';
import { useAtomicFormsCase } from '../api/queries/atomicFormsCase';
import { usePathSelectionSubmit } from '../api/mutations/pathSelectionSubmit';
import { useSubmitAnswers } from '../api/mutations/submitAnswers';
import useSessionQuery from '../api/queries/session';
import useStore from '../app/store';

const UIToolboxSubmit = () => {
  const {
    data: report,
    isLoading: reportLoading,
    isFetching: reportFetching,
  } = useDaVinciReportQuery();
  const { mutate: submitLead } = usePathSelectionSubmit('toolbox');
  const { mutate: submitAnswers } = useSubmitAnswers();
  const { data: session } = useSessionQuery();
  const { answers, token } = useStore();

  const { data, isLoading, isFetching, error } = useAtomicFormsCase({
    onSuccess: () => {
      submitLead();
      submitAnswers({
        userId: session?.user.user_id || '',
        answers: answers,
        token: token,
        questionnaireName: 'toolbox',
      });
    },
  });
  const anyLoading = isLoading || reportLoading || reportFetching || isFetching;

  if (anyLoading) {
    return (
      <UIQuestionnaireDashboardStatus loading={anyLoading} error={error} />
    );
  }
  if (error) {
    return (
      <>
        <UIContainer verticalAlignment="center">
          <div data-testid="success-page" className="text-center">
            <div className="mx-auto flex items-center justify-center h-20 w-20 rounded-full bg-red-100 my-3">
              <XMarkIcon
                className="h-10 w-10 text-red-600"
                aria-hidden="true"
              />
            </div>
            <UIHeader level="3" className="mb-3">
              An error has occurred
            </UIHeader>
            <UIParagraph>
              Something went wrong with your submission. Please go back and try
              to submit again.
            </UIParagraph>
            <div className="flex flex-row space-x-3 my-3 justify-center">
              <Link to="../toolbox-form">
                <UIButton use="primary">Back to form</UIButton>
              </Link>
            </div>
          </div>
        </UIContainer>
      </>
    );
  }

  if (data && report) {
    return (
      <>
        <UIContainer
          verticalAlignment="center"
          footer={
            <div className="flex flex-col space-y-1 justify-center items-center">
              <a
                href={`${process.env.REACT_APP_AF_URL}u/applicant/cases/${data}/tos-agreement`}
              >
                <UIButton use="primary">Take me to Atomic Forms</UIButton>
              </a>
              <UISmall>Note: You must confirm your identity to proceed</UISmall>
            </div>
          }
        >
          <div className="flex w-full flex-col items-center">
            <div
              data-testid="success-page"
              className="text-left space-y-4 max-w-xl"
            >
              <div className="mx-auto flex items-center justify-center h-20 w-20 rounded-full bg-green-100 my-3">
                <CheckIcon
                  className="h-10 w-10 text-green-600"
                  aria-hidden="true"
                />
              </div>
              <UIHeader level="3" className="mb-4 text-center">
                Thank you!
              </UIHeader>
              <UIParagraph>
                Your entry has been successfully submitted and a case has been
                created for you within our{' '}
                <UIStrong>Atomic Forms document submission system.</UIStrong>
              </UIParagraph>
              <UIParagraph>
                To continue the process, on the next page you will need to{' '}
                <UIStrong>verify your identity</UIStrong> either with the phone
                number you provided (if it's a mobile number), or your email
                address.
              </UIParagraph>
            </div>
          </div>
        </UIContainer>
      </>
    );
  } else {
    return <UIParagraph>Unexpected Error.</UIParagraph>;
  }
};
export default UIToolboxSubmit;
