import ReactGA from 'react-ga4';

const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
const ENV = process.env.NODE_ENV;

const allowedEnvs = ['production', 'staging'];

export const initGA = (): void => {
  if (!ENV || !allowedEnvs.includes(ENV)) return;
  if (!GA_TRACKING_ID) return;

  ReactGA.initialize(GA_TRACKING_ID);
};
