import { Dispatch, SetStateAction } from 'react';
import { UIRadioButtonGroup } from '@team-and-tech/ui-components';
import { Question } from '../questions/types';
interface Props {
  answer: any[];
  question: Question;
  setAnswer: Dispatch<SetStateAction<any>>;
}

const UIAnswerRadioButtons = ({ answer, question, setAnswer }: Props) => {
  return (
    <UIRadioButtonGroup
      name={question.id}
      onChange={setAnswer}
      options={question.options}
      value={answer}
      // Fix for css inheritance bug in DS ENG-1475
      className="[&>div>div:last-child]:border-r-gray-300"
    />
  );
};

export default UIAnswerRadioButtons;
