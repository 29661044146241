export const CARDIAC_RESP_ANSWERS = [
  [
    '8C3BF9C9-A20B-45E4-9330-7B9F83EBBA10',
    ['HEART_DEFIBRILATOR_AUTOMATIC_IMPLANTABLE_CARDIOVERTERDEFIBRILLATOR_AICD'],
  ],
  [
    '94F34B87-5266-4655-AA8C-65436AF3ECE1',
    [
      'I_NEVER_APPLIED_FOR_THIS_AND_I_DID_NOT_HAVE_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
      'I_NEVER_APPLIED_FOR_THIS_BUT_I_HAD_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
    ],
  ],
  [
    '29B54E43-CF45-49A5-9502-8CED90B1C1DC',
    ['OBSTRUCTIVE_SLEEP_APNEA_OSA_OR_SLEEP_APNEA'],
  ],
  [
    'BC3384AE-622C-4168-92FE-2B8E7FADFCD1',
    [
      'I_NEVER_APPLIED_FOR_THIS_AND_I_DID_NOT_HAVE_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
      'I_NEVER_APPLIED_FOR_THIS_BUT_I_HAD_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
    ],
  ],
  ['BB9A9F95-22F0-40FC-A7A6-D6382A6A0D5C', 'YES'],
  ['3F626FDB-9ACB-4090-B6E7-859A25E0675E', 'YES'],
];
