import axios from 'axios';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { USER_DATA_QUESTION_IDS } from './session';
import { Question } from '../../questions/types';
import getQuestionnaireDataById from '../../questionnaires/getQuestionnaireDataById';
import { EZFields } from '../../questionnaires/data/526ez_fields';
import useStore from '../../app/store';

const mapAnswersToToolboxPayload = (
  currentAnswers: Map<string, string>,
  questionsMap: Map<string, Question>,
  predictedClaims: string[],
) => {
  let payload: {
    query: string;
    variables: {
      d: {
        created_by: string;
        state: string;
        referral_packet: {
          [key: string]: string;
        };
      };
    };
  } = {
    query:
      'mutation($d:CreateCaseFromPacketIn!){case:create_case_from_packet(d:$d){id}}',
    variables: {
      d: {
        created_by: process.env.REACT_APP_DAVINCI_USER_TOKEN || '',
        state: 'benefits_selected',
        referral_packet: {
          forms: '526ez, va_coversheet',
        },
      },
    },
  };
  // Initialize using the full list of fields to ensure Atomic Forms shows all
  // fields to user
  EZFields.forEach((value) => {
    payload.variables.d.referral_packet[value] = '';
  });
  currentAnswers.forEach((value: string | string[], id: string) => {
    const question = questionsMap.get(id);
    const afKey = question?.af_key;
    if (!afKey) return;
    if (Array.isArray(value)) {
      return (payload.variables.d.referral_packet[afKey] = value.join(', '));
    }
    if (question?.input_type === 'CURRENCY') {
      return (payload.variables.d.referral_packet[afKey] = (
        Number.parseInt(value) / 100
      ).toString());
    }
    return (payload.variables.d.referral_packet[afKey] = value);
  });
  const fullName = currentAnswers.get(USER_DATA_QUESTION_IDS.name);
  const phone = currentAnswers.get(USER_DATA_QUESTION_IDS.phone_number);
  const email = currentAnswers.get(USER_DATA_QUESTION_IDS.email);

  // BEGIN special answers that can't be handled through simple iteration
  payload.variables.d.referral_packet['first_name'] =
    fullName?.split(' ')[0] || '';
  payload.variables.d.referral_packet['middle_initial'] = '';
  payload.variables.d.referral_packet['last_name'] =
    fullName?.split(' ')[1] || '';
  payload.variables.d.referral_packet['mobile'] = phone || '';
  payload.variables.d.referral_packet['email'] = email || '';
  payload.variables.d.referral_packet['refer1'] = 'DaVinci Toolbox';

  // Set predicted claims and associated fields
  payload.variables.d.referral_packet['claims'] = predictedClaims.join(', ');
  predictedClaims.forEach((claim, idx) => {
    payload.variables.d.referral_packet[`claim${idx + 1}`] = claim;
  });

  return payload;
};

const submitAtomicFormsData =
  (
    answers: Map<string, any>,
    claims: string[],
    questionsMap: Map<string, Question>,
  ) =>
  async () => {
    try {
      if (claims.length === 0) {
        throw new Error('No claims report to send.');
      }
      if (answers.size === 0) {
        throw new Error('No answers to send.');
      }

      const payload = mapAnswersToToolboxPayload(answers, questionsMap, claims);
      if (!process.env.REACT_APP_AF_API_URL) {
        throw new Error('No endpoint for submission.');
      }
      let response = await axios.post(
        process.env.REACT_APP_AF_API_URL,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.data?.data?.case?.id) {
        return response.data.data.case.id;
      } else {
        throw new Error('The submission endpoint returned an error.');
      }
    } catch (e) {
      throw e;
    }
  };

export const useAtomicFormsCase = (options?: UseQueryOptions<string>) => {
  const { answers, selectedClaims } = useStore();
  const { questionsMap } = getQuestionnaireDataById('toolbox');
  const isEnabled = !!answers && !!selectedClaims;

  return useQuery<string>(
    ['atomicFormsCase'],
    submitAtomicFormsData(
      answers || new Map(),
      selectedClaims || [],
      questionsMap,
    ),
    {
      enabled: isEnabled,
      staleTime: Infinity,
      cacheTime: Infinity,
      ...options,
    },
  );
};
