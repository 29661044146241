import { StateType, UITextInput } from '@team-and-tech/ui-components';
import { Dispatch, SetStateAction } from 'react';
import { Question } from '../questions/types';
import { PatternFormat } from 'react-number-format';
interface Props {
  answer: any;
  question: Question;
  setAnswer: Dispatch<SetStateAction<any>>;
  onBlur?: (e: any) => void;
  state?: StateType;
}

export default function UIAnswerSsn({
  answer = '',
  question,
  setAnswer,
  onBlur,
  state,
}: Props) {
  return (
    <PatternFormat
      name={question.id}
      onValueChange={({ formattedValue }) => setAnswer(formattedValue)}
      data-testid={question.id}
      value={answer}
      customInput={UITextInput}
      onBlur={onBlur}
      format="###-##-####"
      placeholder="000-00-0000"
      // This makes the numpad keyboard appear in mobile
      type="tel"
      className={'w-full sm:w-80 rounded-md'}
      state={state}
    />
  );
}
