import {
  UIButton,
  UIContainer,
  UIHeader,
  UIParagraph,
  UISmall,
} from '@team-and-tech/ui-components';
import {
  ChevronUpIcon,
  ArrowLeftCircleIcon,
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { useState } from 'react';
import UIComparisonModal from './UIComparisonModal';

const UIToolboxDetails = () => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <UIContainer
        verticalAlignment="center"
        header={
          <div className="flex flex-row items-center">
            <Link to="../plans" className="block">
              <ArrowLeftCircleIcon className="text-primary h-8 w-8 mr-3" />
            </Link>
            <UIHeader level="1">
              Veteran's Toolbox
              <span className="align-super text-brand-red-300 uppercase font-bold ml-1 text-xs">
                BETA
              </span>
            </UIHeader>

            <p className="ml-auto rounded-sm bg-white py-1 pt-1.5 px-3 text-xs font-semibold text-gray-800 border">
              DIY
            </p>
          </div>
        }
      >
        <div className="space-y-6">
          <UIParagraph className="my-3">
            Our free suite of simple tools you can use to file your VA benefits
            application.
          </UIParagraph>
          <div className="flex flex-col">
            <Disclosure defaultOpen>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`flex w-full px-4 py-3 text-left text-lg hover:bg-brand-blue-100 focus:outline-none focus-visible:ring focus-visible:ring-primary items-center ${
                      open ? 'bg-brand-blue-100' : ''
                    }`}
                  >
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-gray-500 mr-3`}
                    />
                    What Do I Get?
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-gray-700 pl-5 sm:pl-10 py-5">
                    <ul className="list-disc ml-3 space-y-1">
                      <li>
                        <span className="font-bold">Data-driven analysis</span>{' '}
                        of your symptoms and medical history
                      </li>
                      <li>
                        <span className="font-bold">
                          Simple, effective tools
                        </span>{' '}
                        to help you file a VA disability benefits application
                      </li>
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure defaultOpen>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`flex w-full px-4 py-3 text-left text-lg hover:bg-brand-blue-100 focus:outline-none focus-visible:ring focus-visible:ring-primary items-center ${
                      open ? 'bg-brand-blue-100' : 'border-t-2 border-blue-100'
                    }`}
                  >
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-gray-500 mr-3`}
                    />
                    What Do I Pay?
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-gray-700 pl-5 sm:pl-10 py-5">
                    <UIHeader level="4" className="mb-2">
                      Nothing.
                    </UIHeader>
                    <UIParagraph>
                      Because it’s so important to us to help every veteran,
                      this service is completely free.
                    </UIParagraph>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`flex w-full px-4 py-3 text-left text-lg hover:bg-brand-blue-100 focus:outline-none focus-visible:ring focus-visible:ring-primary items-center ${
                      open ? 'bg-brand-blue-100' : 'border-t-2 border-blue-100'
                    }`}
                  >
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-gray-500 mr-3`}
                    />
                    How Long Will it Take?
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-gray-700 pl-5 sm:pl-10 py-5">
                    <ul className="list-disc ml-3 space-y-1">
                      <li>Start as soon as today.</li>
                      <li>
                        With our Toolbox service, when you file is up to you!
                      </li>
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`flex w-full px-4 py-3 text-left text-lg hover:bg-brand-blue-100 focus:outline-none focus-visible:ring focus-visible:ring-primary items-center ${
                      open ? 'bg-brand-blue-100' : 'border-t-2 border-blue-100'
                    }`}
                  >
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-gray-500 mr-3`}
                    />
                    Is This Right for Me?
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-gray-700 pl-5 sm:pl-10 py-5">
                    <UIHeader level="4" className="sm:-ml-3 mb-2">
                      We recommend Toolbox for:
                    </UIHeader>
                    <ul className="list-disc ml-3 space-y-1">
                      <li>
                        Veterans who feel confident that they know how the VA
                        works
                      </li>
                      <li>Veterans with simpler, less complex conditions</li>
                      <li>Veterans who just want help “getting it done”</li>
                      <li>Veterans who can’t afford other options</li>
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
          <Link to="../toolbox-start" className="block">
            <UIButton use="primary">
              <div>
                Get Started With This Plan
                <br />
                <UISmall className="text-white font-light">
                  (No Obligation)
                </UISmall>
              </div>
            </UIButton>
          </Link>
          <UIParagraph>
            Still Deciding?{' '}
            <span
              onClick={() => setModalOpen(true)}
              className="text-primary underline cursor-pointer"
            >
              Compare Plans
            </span>
          </UIParagraph>
        </div>
      </UIContainer>
      <UIComparisonModal open={modalOpen} setOpen={setModalOpen} />
    </>
  );
};
export default UIToolboxDetails;
