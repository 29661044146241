import { Disclosure } from '@headlessui/react';
import { PhoneIcon } from '@heroicons/react/24/outline';
import { UIAnchor, UIParagraph } from '@team-and-tech/ui-components';
import TrajectorLogo from '../svg/TrajectorLogo';
import TrajectorLogoFull from '../svg/TrajectorLogoFull';

const Navbar = () => {
  return (
    <Disclosure as="nav" className="bg-white shadow px-4 z-10">
      <div className="flex h-16 justify-between items-center container mx-auto">
        <div className="max-w-[200px] hidden sm:block">
          <TrajectorLogoFull />
        </div>
        <div className="sm:hidden">
          <TrajectorLogo />
        </div>
        <div className="flex flex-row space-x-3 sm:space-x-4 items-center justify-center">
          <div className="flex flex-col sm:flex-row sm:space-x-3 items-center">
            <UIParagraph className="!text-xs sm:!text-sm">
              Need Help?
            </UIParagraph>
            <UIAnchor
              href="tel:18444120882"
              className="no-underline text-xs sm:!text-sm"
            >
              <PhoneIcon className="h-4 w-4 inline-block mr-1" />
              1-844-412-0882
            </UIAnchor>
          </div>
          {/* <UIParagraph className="!text-sm">or</UIParagraph>
          <a href="mailto:support@trajector.com">
            <UIButton use="primary" className="!text-sm py-1 px-2">
              Email
            </UIButton>
          </a> */}
        </div>
      </div>
    </Disclosure>
  );
};

export default Navbar;
