import { Question } from './types';

/**
 * Takes the map of current values and a question and returns a boolean which dictates whether or not the question should appear according to its displayRules
 * @param {Map<string, any | any[]>} answersMap
 * @param {Question} question
 * @returns {boolean} boolean
 */

export const shouldDisplayQuestion: (
  answersMap: Map<string, any | any[]>,
  question: Question,
) => boolean = (answersMap, question) => {
  // If there are no display rules, question should be displayed always
  if (!question?.displayRules) {
    return true;
  }
  const booleans = question.displayRules.map((displayRule) => {
    // The answer that the question relies upon for its display rules
    const requiredAnswer = answersMap.get(displayRule?.questionId || '');
    if (displayRule?.condition === 'exists') {
      return !!requiredAnswer;
    }
    // If there is a required answer value to check against then:
    if (requiredAnswer) {
      if (displayRule?.condition === 'equals') {
        return requiredAnswer === displayRule.value;
      }
      if (displayRule?.condition === 'contains') {
        return displayRule?.value?.some((r: any) =>
          requiredAnswer?.includes(r),
        );
      }
      return false;
    }
    return false;
  });
  return booleans?.every((v) => v === true);
};
