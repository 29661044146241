import { XMarkIcon } from '@heroicons/react/24/outline';
import {
  UIContainer,
  UIHeader,
  UIParagraph,
} from '@team-and-tech/ui-components';

const InviteError = () => {
  return (
    <UIContainer verticalAlignment="center">
      <div className="flex flex-col space-y-3">
        <div className="mx-auto flex items-center justify-center h-40 w-40 rounded-full bg-red-100 my-3">
          <XMarkIcon className="h-24 w-24 text-red-600" aria-hidden="true" />
        </div>
        <UIHeader level="3" className="text-center">
          Authentication error
        </UIHeader>
        <UIParagraph className="text-center">
          It looks like either your session has expired or your invitation link
          is invalid.
        </UIParagraph>{' '}
        <UIParagraph className="text-center">
          Try navigating back, or use the link you received in your email to
          return to your questionnaires.
        </UIParagraph>
      </div>
    </UIContainer>
  );
};

export default InviteError;
