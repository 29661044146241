import { Dispatch, SetStateAction } from 'react';
import { Question } from '../questions/types';
import { UIRadioGroup } from '@team-and-tech/ui-components';

interface Props {
  answer: any;
  question: Question;
  setAnswer: Dispatch<SetStateAction<any>>;
}

export default function UIAnswerRadios({ answer, question, setAnswer }: Props) {
  return (
    <UIRadioGroup
      name={question.id}
      options={question.options}
      onChange={(e: any) => setAnswer(e.target.value)}
      value={answer}
    />
  );
}
