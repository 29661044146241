import { ReactNode } from 'react';
import { UIHeader } from '@team-and-tech/ui-components';
import { UIParagraph } from '@team-and-tech/ui-components';

interface UIQuestionnaireSectionProps {
  children: ReactNode;
  description?: string;
  header?: string;
}

const UIQuestionnaireSection = ({
  children,
  description,
  header,
}: UIQuestionnaireSectionProps) => {
  return (
    <>
      <div className="space-y-8">
        {(description || header) && (
          <div>
            {header && (
              <UIHeader className={description && 'mb-1'} level="2">
                {header}
              </UIHeader>
            )}
            {description && (
              <UIParagraph className="!text-gray-500">
                {description}
              </UIParagraph>
            )}
          </div>
        )}
        {children}
      </div>
    </>
  );
};

export default UIQuestionnaireSection;
