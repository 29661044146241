import { Transition, Dialog } from '@headlessui/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { UIButton, UISmall } from '@team-and-tech/ui-components';
import { Fragment, ReactNode } from 'react';

type BaseConfirmationModalProps = {
  content: ReactNode;
  open: boolean;
  setOpen: (value: boolean) => void;
  title: string;
  loading?: boolean;
};

type CustomButtonsProps = {
  callback?: undefined;
  buttons: ReactNode;
};

type WithCallbackProps = {
  callback: () => void;
  buttons?: undefined;
};

type CallbackOrButtons = CustomButtonsProps | WithCallbackProps;

type UIConfirmationModalProps = BaseConfirmationModalProps & CallbackOrButtons;

const UIConfirmationModal = ({
  content,
  buttons,
  callback,
  open,
  setOpen,
  title,
  loading,
}: UIConfirmationModalProps) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100">
                  <QuestionMarkCircleIcon
                    className="h-6 w-6 text-blue-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                  <div className="mt-2 text-center">{content}</div>
                </div>

                {typeof callback === 'undefined' ? (
                  buttons
                ) : (
                  <div className="mt-5 sm:mt-4 grid grid-cols-1 sm:grid-cols-2 gap-3">
                    <div className="flex flex-col space-y-1 text-center sm:order-last">
                      <UIButton
                        onClick={() => callback()}
                        use="primary"
                        state={loading ? 'disabled' : 'default'}
                      >
                        Yes, I'm Sure
                      </UIButton>
                      <UISmall>No obligation</UISmall>
                    </div>
                    <div className="flex flex-col space-y-1 text-center">
                      <UIButton
                        onClick={() => setOpen(false)}
                        use="tertiary"
                        className="flex-grow-0"
                        state={loading ? 'disabled' : 'default'}
                      >
                        No, Go Back
                      </UIButton>
                      <span className="select-none">&nbsp;</span>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UIConfirmationModal;
