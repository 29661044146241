export const PSYCH_ENT_ANSWERS = [
  [
    '5367A766-687C-4062-894F-CEC947D61145',
    ['PTSD_POST_TRAUMATIC_STRESS_DISORDER'],
  ],
  [
    '458DB969-D78D-469C-8AD3-D81518EFF062',
    [
      'I_NEVER_APPLIED_FOR_THIS_BUT_I_HAD_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
      'I_NEVER_APPLIED_FOR_THIS_AND_I_DID_NOT_HAVE_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
    ],
  ],
  ['B73BDA56-B68B-4A6D-B2E0-5352742D186E', ['DIFFICULTY_HEARING_ON_LEFT_SIDE']],
  [
    'CBB4D561-6128-4368-8863-3C0591EFA276',
    [
      'I_NEVER_APPLIED_FOR_THIS_AND_I_DID_NOT_HAVE_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
      'I_NEVER_APPLIED_FOR_THIS_BUT_I_HAD_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
    ],
  ],
];
