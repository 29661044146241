import { UIAlert, UIHeader } from '@team-and-tech/ui-components';

const RetiredPayWaiverHeader = () => {
  return (
    <>
      <UIHeader level="3">About the Retirement Pay Waiver below:</UIHeader>
      <UIAlert
        className="!mt-2"
        contentNode="Submission of this application constitutes a waiver of military retired pay in an amount equal to VA compensation awarded, if you are entitled to both benefits. Your retired pay may be reduced by the amount of VA compensation awarded. Receipt of the full amount of military retired pay and VA compensation at the same time may result in an overpayment, which may be subject to collection. If you qualify for concurrent receipt of VAcompensation and military retired pay, the waiver of retired pay will not apply. If you do not want to waive any retired pay to receive VA compensation, you should check the box directly below."
        severity="info"
      />
    </>
  );
};

export default RetiredPayWaiverHeader;
