import React from 'react';
import useCachedAnswersMap from '../sessionCache/useCachedAnswersMap';
import useCachedToken from '../sessionCache/useCachedToken';

const useStore = () => {
  return React.useContext(StoreContext);
};

interface StoreContextValue {
  answers: Map<any, any>;
  setAnswers: React.Dispatch<React.SetStateAction<Map<any, any>>>;
  token: string;
  setToken: React.Dispatch<React.SetStateAction<string>>;
  selectedClaims: string[];
  setSelectedClaims: React.Dispatch<React.SetStateAction<string[]>>;
}

const StoreContext = React.createContext<StoreContextValue>({
  answers: new Map(),
  setAnswers: () => {},
  token: '',
  setToken: () => {},
  selectedClaims: [],
  setSelectedClaims: () => {},
});

export interface StoreProviderProps {
  value?: StoreContextValue;
  children: React.ReactNode;
}

export const StoreProvider = ({ children }: StoreProviderProps) => {
  const { answersMap: cachedAnswersMap } = useCachedAnswersMap();
  const { token: cachedToken } = useCachedToken();
  // TODO: refactor into reducer
  const [answers, setAnswers] = React.useState(cachedAnswersMap || new Map());
  const [token, setToken] = React.useState(cachedToken);
  const [selectedClaims, setSelectedClaims] = React.useState([] as string[]);

  const value = {
    answers,
    setAnswers,
    token,
    setToken,
    selectedClaims,
    setSelectedClaims,
  };

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};

export default useStore;
