import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getClient } from '../client';
import { Survey } from '../../questionnaires/types';

export const useGenerateReport = () => {
  const client = getClient();
  const queryClient = useQueryClient();

  return useMutation(
    ({
      userId,
      completedQuestionnaires,
      token,
    }: {
      userId: string;
      completedQuestionnaires: Survey[];
      token: string;
    }) =>
      client.generateReport(
        userId,
        {
          surveys: completedQuestionnaires,
        },
        {
          headers: {
            'X-Trajector-Invite': token,
          },
        },
      ),
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['claims'] }),
    },
  );
};
