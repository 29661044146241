import { StateType, UITextInput } from '@team-and-tech/ui-components';
import { Dispatch, SetStateAction } from 'react';
import { InputType, Question } from '../questions/types';
interface Props {
  answer: any;
  question: Question;
  setAnswer: Dispatch<SetStateAction<any>>;
  onBlur?: (e: any) => void;
  state?: StateType;
}

export default function UIAnswerText({
  answer = '',
  question,
  setAnswer,
  onBlur,
  state,
}: Props) {
  return (
    <UITextInput
      name={question.id}
      data-testid={question.id}
      className={
        question.input_type === InputType.NAME ||
        question.input_type === InputType.EMAIL ||
        question.id === 'EF1925D3-2F1E-4EA9-B282-7E7F5C1FA879'
          ? 'max-w-xs'
          : ''
      }
      onChange={(e: any) => setAnswer(e.target.value)}
      value={answer}
      onBlur={onBlur}
      state={state}
    />
  );
}
