import { ReactNode } from 'react';
import { StateType } from '@team-and-tech/ui-components';
import { getQuestionStateClasses } from './classes';
import { UIFeedback } from '@team-and-tech/ui-components';
import { UILegend } from '@team-and-tech/ui-components';
import { getLabelClass } from '@team-and-tech/ui-components';

interface UIQuestionLayoutProps {
  'data-testid'?: string;
  children: ReactNode;
  description?: string;
  feedback?: string;
  isRequired?: boolean;
  label: string;
  name: string;
  state?: StateType;
}

export default function UIQuestionLayout({
  children,
  'data-testid': testId,
  description = '',
  feedback = '',
  isRequired = true,
  label = '',
  name,
  state = 'default',
}: UIQuestionLayoutProps) {
  return (
    <div
      className={`mb-3 transition-all ${getQuestionStateClasses(state)}`}
      data-testid={testId}
    >
      <fieldset
        className="flex flex-col"
        aria-describedby={`${name}-description`}
      >
        {label && (
          <div
            className={`inline-flex items-center ${
              description ? 'mb-1' : 'mb-3'
            }`}
          >
            {isRequired && <span className={`pr-1 text-red-600`}>* </span>}
            <UILegend state={state} className={`text-lg leading-6 !mb-0`}>
              {label}
            </UILegend>
          </div>
        )}
        {description && (
          <p
            id={`${name}-description`}
            className={`mb-3 ${getLabelClass(state)}`}
          >
            {description}
          </p>
        )}
        {children}
        {feedback && (
          <UIFeedback name={name} state={state} className="mt-2">
            {feedback}
          </UIFeedback>
        )}
      </fieldset>
    </div>
  );
}
