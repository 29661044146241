import { Dispatch, SetStateAction } from 'react';
import { Question } from '../questions/types';
import { UISelect } from '@team-and-tech/ui-components';

interface Props {
  answer: any;
  question: Question;
  setAnswer: Dispatch<SetStateAction<any>>;
}

export default function UIAnswerSelect({ answer, question, setAnswer }: Props) {
  return (
    <div className="max-w-xs">
      <UISelect
        name={question.id}
        options={question.options}
        onChange={setAnswer}
        value={answer}
      />
    </div>
  );
}
