// This is a stop gap for testing and demonstration.
import { XMarkIcon } from '@heroicons/react/24/outline';
import {
  UIAnchor,
  UICode,
  UIContainer,
  UIHeader,
  UIParagraph,
} from '@team-and-tech/ui-components';
import { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { getClient } from '../api/client';
import {
  NewUser,
  NewUserSurveysInvitation,
  Survey,
} from '@team-and-tech/trajector-cloud-api-client';
import { useQuery } from '@tanstack/react-query';
import { getEnv } from '../api/getEnv';

export const TRAJECTOR_GENERATE_INVITE = 'trajector:generate';
const API_KEY = () =>
  process.env.REACT_APP_X_TRAJECTOR_API_KEY || 'API_KEY_NOT_LOADED';

const GENERATED_EMAIL = `sample.user+${Math.floor(
  (crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32) * 10000000,
)}@trajector.com`;

const makeInviteRequest = () => {
  const flag = localStorage.getItem(TRAJECTOR_GENERATE_INVITE) === 'true';
  if (!flag)
    return Promise.reject(new Error("'trajector:generate' !== 'true'"));

  const client = getClient();
  const options = {
    headers: {
      'X-Trajector-Api-Key': API_KEY(),
    },
  };
  const newUser: NewUser = {
    first_name: 'John',
    last_name: 'Sample-User',
    email: GENERATED_EMAIL,
    phone_number: '7874939139',
  };
  return client
    .createUser(newUser, options)
    .then((response) => {
      if (response.status === 200 && response.data) {
        const invite: NewUserSurveysInvitation = {
          user_id: response.data.user_id,
          surveys: [Survey.Background],
        };
        return client.createUserSurveysInvite(invite, options);
      }
    })
    .then((response) => {
      if (response && response.status === 200 && response.data) {
        const link = new URL(response.data.link);
        return link.pathname;
      }
    });
};

export const GenerateInvite = () => {
  const generateFlag =
    localStorage.getItem(TRAJECTOR_GENERATE_INVITE) === 'true';
  const [url, setUrl] = useState<string | null>(null);
  const { data, isLoading, error } = useQuery(
    ['generateInvite'],
    makeInviteRequest,
    { staleTime: Infinity, cacheTime: Infinity },
  );

  useEffect(() => {
    if (data) {
      setUrl(data);
    }
  }, [data]);
  if (!['staging', 'development'].includes(getEnv())) {
    return <Navigate to="/" />;
  }

  if (generateFlag === false) {
    return (
      <UIContainer verticalAlignment="center">
        <div data-testid="generate-error" className="text-center">
          <div className="mx-auto flex items-center justify-center h-40 w-40 rounded-full bg-red-100 my-3">
            <XMarkIcon className="h-24 w-24 text-red-600" aria-hidden="true" />
          </div>
          <UIHeader level="3" className="mb-2">
            Sorry! Something went wrong.
          </UIHeader>
          <UIParagraph className={error ? 'mb-3' : 'mb-6'}>
            We could not generate an invite.
          </UIParagraph>
        </div>
      </UIContainer>
    );
  }

  if (isLoading) {
    return (
      <UIContainer verticalAlignment="center">
        <div data-testid="invite-loading" className="text-center">
          <div className="mx-auto flex items-center justify-center h-40 w-40 rounded-full my-3 bg-blue-50">
            <svg
              className="animate-spin h-36 w-36"
              width="180"
              height="180"
              viewBox="0 0 180 180"
              fill="none"
            >
              <path
                className="fill-blue-400"
                d="M157 90H177C177 41.9512 138.049 3 90 3C41.9512 3 3 41.9512 3 90C3 120.516 18.711 147.362 42.4855 162.891L53.4084 146.135C35.0993 134.175 23 113.501 23 90C23 52.9969 52.9969 23 90 23C127.003 23 157 52.9969 157 90Z"
                fill="#D9D9D9"
              />
            </svg>
          </div>

          <UIHeader level="3" className="my-4">
            Loading...
          </UIHeader>
        </div>
      </UIContainer>
    );
  }
  if (error) {
    return (
      <UIContainer verticalAlignment="center">
        <div data-testid="generate-error" className="text-center">
          <div className="mx-auto flex items-center justify-center h-40 w-40 rounded-full bg-red-100 my-3">
            <XMarkIcon className="h-24 w-24 text-red-600" aria-hidden="true" />
          </div>
          <UIHeader level="3" className="mb-2">
            Sorry! Something went wrong.
          </UIHeader>
          <UIParagraph className={error ? 'mb-3' : 'mb-6'}>
            We could not generate an invite.
          </UIParagraph>
          {error && (
            <UIParagraph className="mb-6">
              <UICode>{JSON.stringify(error)}</UICode>
            </UIParagraph>
          )}
        </div>
      </UIContainer>
    );
  }
  if (data) {
    return (
      <UIContainer>
        <div className="space-y-3">
          <UIParagraph>
            Here's a
            <UIAnchor>
              <Link to={`${url}`}> link </Link>
            </UIAnchor>
            that simulates a generated survey invite.
          </UIParagraph>
          <UIParagraph>The test email related to this link:</UIParagraph>
          <UIParagraph>{GENERATED_EMAIL}</UIParagraph>
        </div>
      </UIContainer>
    );
  }
  return null;
};

export default GenerateInvite;
