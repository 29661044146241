import React from 'react';
import dayjs from 'dayjs';

const ROLLER_INTERVALS = 54;

export const rollText = (str: string, roller = ROLLER_INTERVALS, roll = true) =>
  roll
    ? str
        .split('')
        // Note: there's no need to track any character code rollover here,
        // JS is being nice and doing it correctly for us
        .map((x) => String.fromCharCode(x.charCodeAt(0) + roller))
        .join('')
    : str
        .split('')
        .map((x) => String.fromCharCode(x.charCodeAt(0) - roller))
        .join('');

export const unrollText = (str: string, roller = ROLLER_INTERVALS) => {
  return rollText(str, roller, false);
};

const useCachedValue: <T>(location?: 'local' | 'session') => {
  getValue: (key: string) => T | undefined;
  setValue: (value: T, key: string) => void;
  deleteValue: (key: string) => void;
} = (location = 'session') => {
  const storageLocation =
    location === 'local' ? window.localStorage : window.sessionStorage;

  const setValue = React.useCallback(
    (value, key) => {
      const timeoutMarker = dayjs().add(1, 'hour').toISOString();
      const valueToStore = rollText(
        JSON.stringify({ value, timeout: timeoutMarker }),
      );
      storageLocation.setItem(key, valueToStore);
    },
    [storageLocation],
  );

  const deleteValue = React.useCallback(
    (key) => {
      storageLocation.removeItem(key);
    },
    [storageLocation],
  );

  const getValue = React.useCallback(
    (key) => {
      const timeOfAccess = dayjs().toISOString();
      const stored = storageLocation.getItem(key);
      if (stored === null) {
        return undefined;
      } else {
        const unpackedValue = JSON.parse(unrollText(stored));
        if (timeOfAccess < unpackedValue?.timeout) {
          return unpackedValue.value;
        }
        deleteValue(key);
        return undefined;
      }
    },
    [storageLocation, deleteValue],
  );

  return {
    getValue,
    setValue,
    deleteValue,
  };
};

export default useCachedValue;
