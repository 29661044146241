import { FC } from 'react';
import { Question } from './types';

interface QuestionFooterProps {
  question: Question;
  'data-testid'?: string;
}

/**
 * Hook that dynamically imports a footer component via a filename attached to the question data structure
 * @param componentName filename string of footer component: question.questionFooter
 * @returns null | QuestionFooter<Question> - The footer component, takes a Question as a prop
 */

const useQuestionFooter: (
  componentName?: Question['questionFooter'],
) => FC<QuestionFooterProps> | null = (componentName) => {
  try {
    const QuestionFooter =
      componentName && require(`./components/${componentName}`).default;
    if (QuestionFooter) {
      return QuestionFooter;
    }
  } catch (e) {
    console.error(
      `Couldn't find ${componentName} at ${`./components/${componentName}`}`,
      e,
    );
    return null;
  }
  return null;
};

export default useQuestionFooter;
