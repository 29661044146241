import { ArrowLeftCircleIcon } from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/react';
import { Survey } from '../../questionnaires/types';

import { useQueryClient } from '@tanstack/react-query';
import {
  UIButton,
  UIHeader,
  useNotification,
} from '@team-and-tech/ui-components';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ReactGA from 'react-ga4';
import { Link, useNavigate } from 'react-router-dom';
import { getEnv } from '../../api/getEnv';
import { useGenerateReport } from '../../api/mutations/generateReport';
import { useSubmitAnswers } from '../../api/mutations/submitAnswers';
import {
  USER_DATA_QUESTION_IDS,
  useSessionQuery,
} from '../../api/queries/session';
import useStore from '../../app/store';
import UIQuestion from '../../questions/UIQuestion';
import { shouldDisplayQuestion } from '../../questions/shouldDisplayQuestion';
import { InputType, Question } from '../../questions/types';
import LoadingSpinner from '../../svg/LoadingSpinner';
import getQuestionnaireMockAnswers from '../../test/questionnaireData/getQuestionnaireMockAnswers';
import getQuestionnaireDataById from '../getQuestionnaireDataById';
import { Page } from '../types';
import validateAnswers from '../validateAnswers';
import UIQuestionnaireButtons from './UIQuestionnaireButtons';
import UIQuestionnaireLayout from './UIQuestionnaireLayout';
import UIQuestionnaireSection from './UIQuestionnaireSection';
import UIQuestionnaireSteps from './UIQuestionnaireSteps';
import usePagination from './usePagination';
import { getQuestionnaireCompletionStatus } from '../../questionnaire-dashboard/utils/getQuestionnaireCompletionStatus';

const UIQuestionnaire = ({ id }: { id: Survey | 'toolbox' }) => {
  const navigate = useNavigate();
  const { createNotification } = useNotification();
  const [submitting, setSubmitting] = useState(false);
  const { questionnaire, questionsMap } = useMemo(
    () => getQuestionnaireDataById(id),
    [id],
  );
  const { currentPage, setCurrentPage, increment, decrement } = usePagination(
    questionnaire.pages.length,
  );
  const { answers: answersMap, token: authToken, selectedClaims } = useStore();

  const { data: session } = useSessionQuery();
  const queryClient = useQueryClient();
  const { mutateAsync: submitAnswers } = useSubmitAnswers();
  const { mutateAsync: generateReport } = useGenerateReport();
  const { setAnswers, token } = useStore();

  const completedQuestionnaires = Object.values(Survey).filter(
    (surveyKey: Survey) =>
      getQuestionnaireCompletionStatus(
        surveyKey,
        session?.user.answers?.values,
      ) === 'complete' && surveyKey,
  );

  // Array of questions on current visible page
  const currentPageQuestions = questionnaire.pages[currentPage - 1].questionIds
    .map((id) => questionsMap.get(id)!)
    .filter((question) => {
      return (
        shouldDisplayQuestion(answersMap, question) &&
        // PR#500
        // Ignoring the three User information fields that we're getting
        // from the landing page
        !Object.values(USER_DATA_QUESTION_IDS).includes(question?.id)
      );
    });

  const [showError, setShowError] = useState(
    new Map(currentPageQuestions.map((q) => [q?.id, false])),
  );

  const errors = validateAnswers(answersMap, currentPageQuestions);

  const handleQuestionBlur = React.useCallback(
    (question: Question) => {
      // We only want to trigger more immediate validation for fields with explicit
      // formatting, otherwise immediate feedback might interrupt UX, and otherwise
      // will be triggered by Next or Submit
      if ([InputType.EMAIL, InputType.PHONE].includes(question.input_type)) {
        setShowError(new Map(showError.set(question.id, true)));
      }
    },
    [showError],
  );

  // We only want to restrict users from advancing the page, i.e. no need to handle pageDecrement
  const handlePageIncrement = React.useCallback(
    (pageIncrement: () => void) => {
      const pageErrors = currentPageQuestions.map((q) => errors.get(q.id));
      console.error(pageErrors, currentPageQuestions, errors);
      // if there are no errors visible, let them increment
      if (pageErrors.filter(Boolean).length === 0) {
        return pageIncrement();
      } else {
        // set visible questions as showError to display error messages
        currentPageQuestions.forEach((q) => showError.set(q.id, true));
        setShowError(new Map(showError));
      }
    },
    [currentPageQuestions, errors, showError],
  );

  const handleSetPage = React.useCallback(
    (setPage: Dispatch<SetStateAction<number>>, pageNum: number) => {
      const pageErrors = currentPageQuestions.map((q) => errors.get(q.id));
      // if they're going to a previous page, allow transition without check
      if (pageNum < currentPage) {
        return setPage(pageNum);
      }
      // if page has no errors, let them advance
      if (pageErrors.filter(Boolean).length === 0) {
        return setPage(pageNum);
      } else {
        // trigger showError if there are errors to display the error messages
        setShowError(
          (prev) =>
            new Map([
              ...Array.from(prev),
              ...currentPageQuestions.map(
                (q) => [q.id, true] as [string, boolean],
              ),
            ]),
        );
      }
    },
    [currentPage, currentPageQuestions, errors],
  );

  const handleSubmit = React.useCallback(async () => {
    setSubmitting(true);

    const allDisplayableQuestions = Array.from(questionsMap.values()).filter(
      (question) => {
        return (
          shouldDisplayQuestion(answersMap, question) &&
          // PR#500
          // Ignoring the three User information fields that we're getting
          // from the landing page
          !Object.values(USER_DATA_QUESTION_IDS).includes(question.id)
        );
      },
    );

    const visibleErrors = validateAnswers(answersMap, allDisplayableQuestions);
    // if no errors, submit
    if (visibleErrors.size === 0) {
      if (!session?.user?.user_id) {
        console.error('Invalid user id');
        createNotification('Invalid user id', 'error');
        setSubmitting(false);
        Sentry.captureException(
          `Invalid user id error after user submitted form. Form:${id}`,
        );
        throw new Error('Invalid user id');
      }
      if (id === 'toolbox') {
        //
        // Toolbox form is sent to special submission view
        ///
        navigate('../toolbox-submit');
      } else {
        //
        // Normal submission path for condition questionnaires
        //
        try {
          await submitAnswers(
            {
              userId: session.user.user_id,
              answers: answersMap,
              token: authToken,
              questionnaireName: id,
            },
            {
              // We're having the questionnaires request report
              // generation until message queueing is a thing.
              // We don't want to interrupt progress in case of
              // an error in report gen. So we log and move on.
              onSuccess: () => {
                queryClient.invalidateQueries({
                  queryKey: ['session'],
                });
                generateReport(
                  {
                    userId: session.user.user_id,
                    completedQuestionnaires: [...completedQuestionnaires, id],
                    token: authToken,
                  },
                  {
                    onError: (error) => {
                      setSubmitting(false);
                      console.debug(error);
                      Sentry.captureException(error, {
                        extra: {
                          description:
                            'Failed request to reports/generate endpoint.',
                        },
                      });
                    },
                    onSuccess: () => {
                      setSubmitting(false);
                      navigate(`/invite/${token}`);
                      queryClient.invalidateQueries({
                        queryKey: ['claims'],
                      });
                    },
                  },
                );
                ReactGA.event('questionnaire_completed', {
                  scope: 'user',
                  questionnaireName: id,
                });
              },
            },
          );
        } catch (error: any) {
          setSubmitting(false);
          Sentry.captureException(error, {
            extra: {
              description: 'Failed questionnaire submission.',
            },
          });
          createNotification(
            error?.message || 'Failed to submit answers. Please try again.',
            'error',
          );
        }
      }
    } else {
      //
      // find the first error, navigate the user there and touch it to indicate the field
      //
      const firstErrorQuestionId = visibleErrors.entries().next().value[0];
      const pageNumber =
        questionnaire.pages.findIndex((page) =>
          page.questionIds.includes(firstErrorQuestionId),
        ) + 1;
      setSubmitting(false);
      setCurrentPage(pageNumber);
      setShowError(new Map(showError.set(firstErrorQuestionId, true)));
      const errorMessage =
        visibleErrors.size > 1
          ? `Some questions still need answers. The first is on step ${pageNumber}.`
          : `A question on step ${pageNumber} needs an answer.`;
      createNotification(errorMessage, 'info', {
        title: 'Form is incomplete',
      });
    }
  }, [
    answersMap,
    authToken,
    completedQuestionnaires,
    createNotification,
    generateReport,
    id,
    submitAnswers,
    navigate,
    queryClient,
    questionnaire.pages,
    questionsMap,
    session?.user.user_id,
    setCurrentPage,
    showError,
    token,
  ]);

  // We need this guard in place to make sure the user is submitting the toolbox
  // form with selected claims. If there are none (like if they reloaded the
  // page) we bounce them back to the previous view to reselect them.
  useEffect(() => {
    if (selectedClaims.length === 0 && id === 'toolbox') {
      createNotification(
        'You need to complete condition selection to continue to the toolbox form',
        'warning',
        { title: 'No conditions selected' },
      );
      navigate('../toolbox-start');
    }
  }, [selectedClaims.length, createNotification, navigate, id]);

  return (
    <UIQuestionnaireLayout
      header={
        <div className="flex flex-row items-center">
          {/* We're providing a back button for the toolbox form */}
          <Link
            to={
              id === 'toolbox'
                ? '../toolbox-start'
                : `../../invite/${authToken}`
            }
            className="block"
          >
            <ArrowLeftCircleIcon className="text-primary h-8 w-8 mr-3" />
          </Link>
          <UIHeader className={questionnaire.description && 'mb-1'} level="1">
            {questionnaire.title}
            {id === 'toolbox' && (
              <span className="align-super text-brand-red-300 uppercase font-bold ml-1 text-xs">
                BETA
              </span>
            )}
          </UIHeader>

          {/* If staging or local dev, show a button to fill in values */}
          {['staging', 'development'].includes(getEnv()) && (
            <UIButton
              use="tertiary"
              className="ml-3"
              onClick={() =>
                setAnswers((prevAnswers) => {
                  return new Map([
                    ...Array.from(prevAnswers),
                    ...(getQuestionnaireMockAnswers(questionnaire.id) as any),
                  ]);
                })
              }
            >
              DEBUG
            </UIButton>
          )}
          {/* Toolbox form gets a special header badge */}
          {id === 'toolbox' && (
            <p className="ml-auto rounded-sm bg-white py-1 px-3 text-xs font-semibold text-gray-800 border">
              DIY
            </p>
          )}
        </div>
      }
      description={questionnaire?.description}
      buttons={
        <UIQuestionnaireButtons
          numPages={questionnaire.pages.length}
          nextPage={() => handlePageIncrement(increment)}
          prevPage={decrement}
          onSubmit={handleSubmit}
          currentPage={currentPage}
        />
      }
    >
      {submitting && (
        <div className="absolute left-0 right-0 bottom-0 top-0 bg-white/90 z-[999] flex flex-col items-center justify-center">
          <LoadingSpinner />
          <UIHeader level="3" className="my-4">
            Submitting...
          </UIHeader>
        </div>
      )}
      <div className="mb-8">
        <UIQuestionnaireSteps
          pages={questionnaire.pages}
          currentPage={currentPage}
          setCurrentPage={(args) => handleSetPage(setCurrentPage, args)}
        />
      </div>
      <div className="space-y-8">
        {questionnaire.pages.map(
          (page: Page, idx: number) =>
            idx + 1 === currentPage && (
              <UIQuestionnaireSection key={page.id}>
                {page.questionIds.map((questionId: string) => {
                  const question = questionsMap.get(questionId);
                  return (
                    question && (
                      <UIQuestion
                        key={questionId}
                        question={question}
                        onBlur={() => handleQuestionBlur(question)}
                        error={errors.get(questionId)}
                        showError={showError.get(questionId)}
                      />
                    )
                  );
                })}
              </UIQuestionnaireSection>
            ),
        )}
      </div>
    </UIQuestionnaireLayout>
  );
};

export default UIQuestionnaire;
