import React from 'react';
import useStore from '../app/store';
import { useQueryClient } from '@tanstack/react-query';
import useCachedAnswersMap from './useCachedAnswersMap';
import useDebouncedCallback from 'beautiful-react-hooks/useDebouncedCallback';

// This component's responsibility is to check the cache against the
// current store's version of answers, if the store has been updated,
// it caches that new version.
const AnswersCache = () => {
  const { answers: answersStore } = useStore();
  const { setMap, answersMap } = useCachedAnswersMap();
  const queryClient = useQueryClient();

  const debouncedHandler = useDebouncedCallback(
    () => {
      if (
        JSON.stringify(Array.from(answersStore?.entries() || [])) ===
        JSON.stringify(Array.from(answersMap?.entries() || []))
      ) {
        return;
      }

      // If we've updated the answers store then we can assume that the
      // case information needs to be updated, so a new case needs to be
      // created when the user resubmits the toolbox form (i.e. navigates
      // to ToolboxSubmit view)
      queryClient.invalidateQueries(['atomicFormsCase']);

      setMap(answersStore);
    },
    [answersStore, answersMap, queryClient],
    500,
  );

  React.useEffect(() => {
    debouncedHandler();
  }, [debouncedHandler]);

  return <></>;
};

export default AnswersCache;
