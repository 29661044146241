import {
  UIButton,
  UICheckboxGroup,
  UIContainer,
  UIHeader,
} from '@team-and-tech/ui-components';

import { useCallback, useState } from 'react';
import { useQuestionnaireSelectionSubmit } from '../api/mutations/questionnaireSelection';
import {
  ConditionKeys,
  ConditionToSurveyMap,
  Conditions,
} from './questionnaire-id-mapping';
import { useNavigate } from 'react-router-dom';
import useStore from '../app/store';

const conditionOptions = Object.entries(Conditions).map(
  ([key, conditionName]) => ({
    label: conditionName,
    value: key,
  }),
);

const UIQuestionnaireSelect = () => {
  const { mutateAsync, isLoading } = useQuestionnaireSelectionSubmit();
  const { token } = useStore();

  const navigate = useNavigate();

  const [selectedConditions, setSelectedConditions] = useState<ConditionKeys[]>(
    [],
  );

  const handleOnChange = useCallback((values: ConditionKeys[]) => {
    setSelectedConditions(values);
  }, []);

  const handleSave = useCallback(async () => {
    const selectedSurveys = Array.from(
      new Set(
        selectedConditions.map((condition) => ConditionToSurveyMap[condition]),
      ),
    );

    try {
      await mutateAsync(selectedSurveys);
      navigate(`/invite/${token}`);
    } catch (e) {}
  }, [selectedConditions, navigate, mutateAsync, token]);

  return (
    <UIContainer
      header={<UIHeader level="1">Your Conditions</UIHeader>}
      footer={
        <UIButton
          className="mx-auto w-40"
          onClick={handleSave}
          state={
            isLoading || selectedConditions.length === 0
              ? 'disabled'
              : 'default'
          }
        >
          Next
        </UIButton>
      }
    >
      <div className="space-y-4">
        <section className="space-y-2">
          <UIHeader level="4">
            Choose categories related with your disabilities and conditions
          </UIHeader>
          <p className="text-sm text-gray-500">
            Please select the condition categories that pertain to your current
            health symptoms.
          </p>
        </section>
        <UICheckboxGroup
          className="grid grid-cols-1 md:grid-cols-2"
          name="select"
          value={selectedConditions}
          onChange={handleOnChange}
          options={conditionOptions}
        />
      </div>
    </UIContainer>
  );
};

export default UIQuestionnaireSelect;
