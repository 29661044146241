import { useMutation } from '@tanstack/react-query';
import { getClient } from '../client';
import {
  Answer,
  Answers,
} from '@team-and-tech/trajector-cloud-api-client';
import { Answer as AnswerLocal } from '../../questions/types';
import getQuestionnaireDataById from '../../questionnaires/getQuestionnaireDataById';
import { Survey } from '../../questionnaires/types';

const DATE_QUESTION_IDS = [
  '82B1A3D8-FC3C-4443-BED9-36DC3F91CA3C',
  '4E58432A-BFBF-494A-8845-41E78BEB7515',
  'A4101F99-EFD4-4AE4-B101-82E580C5FA5A',
];

export const mapAnswersToPayload = (
  currentAnswers: Map<string, AnswerLocal['value']>,
  questionnaireName?: Survey | 'toolbox',
) => {
  const answerPayload: Answers = { values: {} };
  // A user could conceivably stop filling a form and never submit it. To
  // prevent sending any data that the user has not explicitly decided upon
  // sending, i.e. a form they did not explicity submit, we filter the answer
  // values based on whether or not they belong in the form currently being
  // submitted.
  const { questionsMap } = questionnaireName
    ? getQuestionnaireDataById(questionnaireName)
    : { questionsMap: undefined };
  currentAnswers.forEach((value: string | number | string[], key: string) => {
    if (questionsMap && !questionsMap.has(key)) {
      return;
    }
    const answer: Answer = {};
    if (Array.isArray(value)) {
      answer.strings = value;
    } else if (DATE_QUESTION_IDS.includes(key)) {
      answer.datetime = `${value}T00:00:00.000Z`;
    } else if (Number.isInteger(value)) {
      answer.integer = value as number;
    } else {
      answer.string = value as string;
    }
    answerPayload.values[key] = answer;
  }, {});
  return answerPayload;
};

export const useSubmitAnswers = () => {
  const client = getClient();

  return useMutation(
    ({
      userId,
      answers,
      token,
      questionnaireName,
    }: {
      userId: string;
      answers: Map<string, AnswerLocal['value']>;
      token: string;
      questionnaireName?: Survey | 'toolbox';
    }) =>
      client.createUserAnswers(
        userId,
        mapAnswersToPayload(answers, questionnaireName),
        {
          headers: {
            'X-Trajector-Invite': token,
          },
        },
      ),
  );
};
