import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getClient } from '../client';
import { useSessionQuery } from './session';
import useStore from '../../app/store';
import { cleanAndDedupeClaims } from '../../lib/clean-and-dedupe-claims';

const getClaimsReport: (
  userId: string,
  token: string,
) => () => Promise<DaVinciQueryReturnType> =
  (userId: string, token: string) => async () => {
    const client = getClient();
    const response = await client.getLatestReport(userId, {
      headers: {
        'X-Trajector-Invite': token,
      },
    });
    if (response.data?.data?.predicted_claims) {
      const cleanedPredicted = cleanAndDedupeClaims(
        response.data?.data?.predicted_claims,
      );
      return {
        predicted: cleanedPredicted,
        serviceConnected: response.data?.data?.current_service_connected || [],
      };
    } else {
      throw new Error('No predicted claims to display.');
    }
  };

type DaVinciQueryReturnType = {
  predicted: string[];
  serviceConnected: string[];
};

export const useDaVinciReportQuery = (
  options?: UseQueryOptions<DaVinciQueryReturnType>,
) => {
  const { token } = useStore();

  const { data: session } = useSessionQuery();

  const userId = session?.user?.user_id || '';
  return useQuery<DaVinciQueryReturnType>(
    ['claims'],
    getClaimsReport(userId, token),
    {
      enabled: !!userId,
      staleTime: 600000,
      ...options,
    },
  );
};
