import UIAnswerCheckboxes from '../answers/UIAnswerCheckboxes';
import UIAnswerSelect from '../answers/UIAnswerSelect';
import UIAnswerText from '../answers/UIAnswerText';
import UIAnswerRadioButtons from '../answers/UIAnswerRadioButtons';
import UIAnswerRadios from '../answers/UIAnswerRadios';
import UIQuestionLayout from './UIQuestionLayout';
import UIAnswerDate from '../answers/UIAnswerDate';
import UIAnswerNumber from '../answers/UIAnswerNumber';
import UIAnswerPhone from '../answers/UIAnswerPhone';
import { useCallback } from 'react';
import { OptionType } from '@team-and-tech/ui-components';
import { Question } from './types';
import UIAnswerTextArea from '../answers/UIAnswerTextArea';
import { shouldDisplayQuestion } from './shouldDisplayQuestion';
import useQuestionFooter from './useQuestionFooter';
import useQuestionHeader from './useQuestionHeader';
import UIAnswerCurrency from '../answers/UIAnswerCurrency';
import UIAnswerCheckbox from '../answers/UIAnswerCheckbox';
import { USER_DATA_QUESTION_IDS } from '../api/queries/session';
import useStore from '../app/store';
import UIAnswerSsn from '../answers/UIAnswerSsn';

interface Props {
  question: Question;
  'data-testid'?: string;
  onBlur?: (e: any) => void;
  error?: string;
  showError?: boolean;
}

const longestOptionLabel = (options: OptionType[]) =>
  Math.max(...options.map((option) => option?.label?.length || 0));

const UIQuestion = ({
  question,
  'data-testid': testId,
  onBlur,
  error,
  showError,
}: Props) => {
  const { answers: answersStore, setAnswers } = useStore();
  const shouldDisplay = shouldDisplayQuestion(answersStore, question);
  const QuestionFooter = useQuestionFooter(question?.questionFooter);
  const QuestionHeader = useQuestionHeader(question?.questionHeader);

  const handleAnswerChange = useCallback(
    (answers: string | any[]) => {
      setAnswers((prevAnswers) => {
        if (answers?.length === 0) {
          prevAnswers.delete(question.id);
        } else {
          prevAnswers.set(question.id, answers);
        }
        return new Map(prevAnswers);
      });
    },
    [question.id, setAnswers],
  );
  if (shouldDisplay === false) {
    return null;
  }
  let Answer;

  if (
    question.input_type === 'SINGLE_LINE' ||
    question.input_type === 'EMAIL' ||
    question.input_type === 'NAME'
  ) {
    Answer = UIAnswerText;
  } else if (question.input_type === 'SSN') {
    Answer = UIAnswerSsn;
  } else if (question.input_type === 'MULTI_LINE') {
    Answer = UIAnswerTextArea;
  } else if (question.input_type === 'DATE') {
    Answer = UIAnswerDate;
  } else if (question.input_type === 'PHONE') {
    Answer = UIAnswerPhone;
  } else if (question.input_type === 'NUMBER') {
    Answer = UIAnswerNumber;
  } else if (question.input_type === 'CURRENCY') {
    Answer = UIAnswerCurrency;
  } else if (question.input_type === 'CHECKBOX') {
    Answer = UIAnswerCheckboxes;
  } else if (question.input_type === 'DROPDOWN') {
    Answer = UIAnswerSelect;
    // New input type added to handle toolbox forms, lots of single checkboxes
  } else if (question.input_type === 'SINGLE_SELECT') {
    Answer = UIAnswerCheckbox;
  } else if (question.input_type === 'RADIO') {
    if (
      question.options.length <= 4 &&
      longestOptionLabel(question.options) < 15
    ) {
      Answer = UIAnswerRadioButtons;
    } else if (question.options.length > 8) {
      Answer = UIAnswerSelect;
    } else {
      Answer = UIAnswerRadios;
    }
  } else {
    return (Answer = null);
  }

  return (
    <>
      {QuestionHeader && (
        <QuestionHeader
          question={question}
          data-testid={`${question.id}_header`}
        />
      )}
      <UIQuestionLayout
        label={question.text}
        name={question.id}
        description={question.description}
        isRequired={question.isRequired}
        feedback={showError && error ? error : undefined}
        state={showError && error ? 'error' : 'default'}
        data-testid={testId}
      >
        <Answer
          answer={answersStore.get(question.id)}
          question={question}
          setAnswer={handleAnswerChange}
          onBlur={onBlur}
          // PR#500
          // Disabling inputs that are displaying the user data questions
          // Name, email, phone
          state={
            Object.values(USER_DATA_QUESTION_IDS).includes(question.id)
              ? 'disabled'
              : 'default'
          }
        />
      </UIQuestionLayout>
      {QuestionFooter && (
        <QuestionFooter
          question={question}
          data-testid={`${question.id}_footer`}
        />
      )}
    </>
  );
};

export default UIQuestion;
