import { Survey } from '../../questionnaires/types';

import { BACKGROUND_ANSWERS } from './BACKGROUND_ANSWERS';
import { CARDIAC_RESP_ANSWERS } from './CARDIAC_RESP_ANSWERS';
import { ENDOCRINE_IMMUNE_ANSWERS } from './ENDOCRINE_IMMUNE_ANSWERS';
import { GENDER_ANSWERS } from './GENDER_ANSWERS';
import { GI_SKIN_ANSWERS } from './GI_SKIN_ANSWERS';
import { MUSCULOSKELETAL_ANSWERS } from './MUSCULOSKELETAL_ANSWERS';
import { PSYCH_ENT_ANSWERS } from './PSYCH_ENT_ANSWERS';
import { TOOLBOX_ANSWERS } from './TOOLBOX_ANSWERS';
import { NERVOUS_CANCER_ANSWERS } from './NERVOUS_CANCER_ANSWERS';

const ANSWERS_MAP = {
  [Survey.Background]: BACKGROUND_ANSWERS,
  [Survey.CardiacRespiratory]: CARDIAC_RESP_ANSWERS,
  [Survey.EndocrineImmune]: ENDOCRINE_IMMUNE_ANSWERS,
  [Survey.Gender]: GENDER_ANSWERS,
  [Survey.GiSkin]: GI_SKIN_ANSWERS,
  [Survey.Musculoskeletal]: MUSCULOSKELETAL_ANSWERS,
  [Survey.NervousCancer]: NERVOUS_CANCER_ANSWERS,
  [Survey.PsychEnt]: PSYCH_ENT_ANSWERS,
  toolbox: TOOLBOX_ANSWERS,
};

const getQuestionnaireMockAnswers = (surveyKey: Survey | 'toolbox') =>
  ANSWERS_MAP[surveyKey];

export default getQuestionnaireMockAnswers;
