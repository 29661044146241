import { UISteps } from '@team-and-tech/ui-components';
import { Page } from '../types';

interface UIQuestionnaireStepsProps {
  pages: Page[];
  currentPage: number;
  setCurrentPage: (value: number) => void;
}

const UIQuestionnaireSteps = ({
  pages,
  currentPage,
  setCurrentPage,
}: UIQuestionnaireStepsProps) => {
  let steps: any[] = [];
  pages.map((page, idx) =>
    steps.push({
      name: `STEP ${idx + 1}`,
      description: page.header as string,
      action: () => setCurrentPage(idx + 1),
      status:
        idx + 1 === currentPage
          ? 'current'
          : idx + 1 >= currentPage
          ? 'upcoming'
          : 'complete',
    }),
  );

  return <UISteps steps={steps} />;
};

export default UIQuestionnaireSteps;
