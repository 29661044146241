import {
  UIAlert,
  UIButton,
  UICheckboxGroup,
  UIContainer,
  UIHeader,
  UIParagraph,
} from '@team-and-tech/ui-components';
import { ArrowLeftCircleIcon } from '@heroicons/react/24/outline';
import UIQuestionnaireDashboardStatus from '../questionnaire-dashboard/UIQuestionnaireDashboardStatus';
import { Link, useNavigate } from 'react-router-dom';
import { useDaVinciReportQuery } from '../api/queries/daVinciReport';
import useStore from '../app/store';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

const UIToolboxStart = () => {
  const navigate = useNavigate();
  const { selectedClaims, setSelectedClaims } = useStore();

  const { data: daVinciReport, isLoading, error } = useDaVinciReportQuery();

  if (isLoading) {
    return <UIQuestionnaireDashboardStatus loading={isLoading} />;
  }
  if (error) {
    return <UIQuestionnaireDashboardStatus error={error} />;
  }

  if (daVinciReport) {
    return (
      <>
        <UIContainer
          verticalAlignment="center"
          header={
            <div className="flex flex-row items-center">
              <Link to="../toolbox" className="block">
                <ArrowLeftCircleIcon className="text-primary h-8 w-8 mr-3" />
              </Link>
              <UIHeader level="1">
                Veteran's Toolbox
                <span className="inline-block align-top text-brand-red-300 uppercase font-bold mb-auto ml-1 text-xs">
                  BETA
                </span>
              </UIHeader>
              <p className="ml-auto rounded-sm bg-white py-1 px-3 text-xs font-semibold text-gray-800 border">
                DIY
              </p>
            </div>
          }
        >
          <div className="space-y-4">
            <UIHeader level="3" className="font-semibold">
              Great!
            </UIHeader>
            <UIParagraph className="my-3">
              You’re getting started with Veteran’s Toolbox!
            </UIParagraph>
            <UIParagraph className="my-3">
              Below is a list containing the potential VA-ratable health
              conditions we identified:
            </UIParagraph>
            <div className="bg-green-100 p-5 rounded-md border border-1 border-green-200 space-y-4">
              <div className="flex flex-row items-center space-x-2">
                <ExclamationTriangleIcon className="h-12 w-12 text-yellow-500" />
                <div>
                  <UIHeader level="4" className="!text-lg">
                    Select Conditions Never Filed with VA
                  </UIHeader>
                  <UIParagraph>
                    Our system may have identified potential new conditions for
                    which you have already filed with the VA. Please select the
                    conditions you have{' '}
                    <span className="font-bold">
                      never filed with the VA before
                    </span>{' '}
                    to continue.
                  </UIParagraph>
                </div>
              </div>
              <hr className="w-full" />
              <UICheckboxGroup
                name="selected_claims"
                options={daVinciReport.predicted.map((claim) => ({
                  value: claim,
                  label: claim,
                }))}
                onChange={setSelectedClaims}
                value={selectedClaims}
              />
            </div>
            <UIParagraph className="my-3">
              Now we’ll ask you some additional questions to be sure we get all
              your details.
            </UIParagraph>
            <UIParagraph className="my-3">
              Then we’ll import your information into a form you can submit to
              the VA.
            </UIParagraph>
            <UIParagraph className="my-3">
              Finally, you may review the form for accuracy, sign it, and submit
              to the VA!
            </UIParagraph>
            <UIAlert contentNode="You’ll be able to add, delete, or change claims. We’ll confirm your identity before you finalize your forms." />
            <UIButton
              use="primary"
              onClick={() => navigate('../toolbox-form')}
              state={selectedClaims.length === 0 ? 'disabled' : 'default'}
            >
              Complete My Details
            </UIButton>
          </div>
        </UIContainer>
      </>
    );
  } else {
    return <UIParagraph>Unexpected Error.</UIParagraph>;
  }
};
export default UIToolboxStart;
