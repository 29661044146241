import { screen, render, fireEvent } from '@testing-library/react';
import { InputType } from '../types';
import UIQuestion from '../UIQuestion';
import * as store from '../../app/store';

jest.mock('../../app/store');

describe('ActiveDutyFooter', () => {
  it('Should be present when applied question has "NO" value', () => {
    jest.spyOn(store, 'default').mockReturnValue({
      answers: new Map([['SOME_ID', 'NO']]),
      setAnswers: () => {},
      token: 'asdf',
      setToken: () => {},
      selectedClaims: [''],
      setSelectedClaims: () => {},
    });
    render(
      <>
        <UIQuestion
          question={{
            id: 'SOME_ID',
            text: 'What color is the sky today?',
            input_type: InputType.RADIO,
            options: [
              { label: 'Yes', value: 'YES' },
              { label: 'No', value: 'NO' },
            ],
            questionFooter: 'ActiveDutyFooter',
          }}
        />
      </>,
    );
    fireEvent.click(screen.getByText(/No/));
    expect(
      screen.getByText(/How To Apply For A Discharge/),
    ).toBeInTheDocument();
  });
  it('Should not be present when value is not equal to "NO"', () => {
    jest.spyOn(store, 'default').mockReturnValue({
      answers: new Map(),
      setAnswers: () => {},
      token: 'asdf',
      setToken: () => {},
      selectedClaims: [''],
      setSelectedClaims: () => {},
    });
    render(
      <>
        <UIQuestion
          question={{
            id: 'SOME_ID',
            text: 'What color is the sky today?',
            input_type: InputType.RADIO,
            options: [
              { label: 'Yes', value: 'YES' },
              { label: 'No', value: 'NO' },
            ],
            questionFooter: 'ActiveDutyFooter',
          }}
        />
      </>,
    );
    expect(
      screen.queryByText(/How To Apply For A Discharge/),
    ).not.toBeInTheDocument();
  });
});
