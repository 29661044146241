import { Dispatch, SetStateAction } from 'react';
import { Question } from '../questions/types';
import { UICheckbox, UIFormElement } from '@team-and-tech/ui-components';

interface Props {
  answer: any;
  question: Question;
  setAnswer: Dispatch<SetStateAction<string>>;
}

export default function UIAnswerCheckboxes({
  answer,
  question,
  setAnswer,
}: Props) {
  return (
    <UIFormElement
      name={question.id}
      label={question.options?.length > 0 ? question.options[0]?.label : ''}
      layout="right"
    >
      <UICheckbox
        name={question.id}
        onChange={(v) => {
          return setAnswer(v.target.checked ? question.options[0].value : '');
        }}
        checked={!!answer}
        value={answer}
      />
    </UIFormElement>
  );
}
