import { UIDivider, UIHeader } from '@team-and-tech/ui-components';

interface AddressHeaderProps {
  'data-testid'?: string;
}

const AddressHeader = ({ 'data-testid': testId }: AddressHeaderProps) => {
  return (
    <div data-testid={testId}>
      <UIHeader level="2" className="!mb-0">
        Mailing Address
      </UIHeader>
      <UIDivider className="!mt-2" />
    </div>
  );
};

export default AddressHeader;
