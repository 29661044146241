export function intersection(A: Set<any>, B: Set<any>): Set<any> {
  const _intersection = new Set();

  B.forEach((element) => {
    if (A.has(element)) {
      _intersection.add(element);
    }
  });

  return _intersection;
}
