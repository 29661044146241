import { FC } from 'react';
import { Question } from './types';

interface QuestionHeaderProps {
  question: Question;
  'data-testid'?: string;
}

/**
 * Hook that dynamically imports a header component via a filename attached to the question data structure
 * @param componentName filename string of header component: question.questionHeader
 * @returns null | QuestionHeader<Question> - The header component, takes a Question as a prop
 */

const useQuestionHeader: (
  componentName?: Question['questionHeader'],
) => FC<QuestionHeaderProps> | null = (componentName) => {
  try {
    const QuestionHeader =
      componentName && require(`./components/${componentName}`).default;
    if (QuestionHeader) {
      return QuestionHeader;
    }
  } catch (e) {
    console.error(
      `Couldn't find ${componentName} at ${`./components/${componentName}`}`,
      e,
    );
    return null;
  }
  return null;
};

export default useQuestionHeader;
