import React from 'react';
import { Survey } from '../../questionnaires/types';
import { UIBadge, UIBadgeTypes } from '@team-and-tech/ui-components';
import { QUESTIONNAIRE_NAMES } from '../QUESTIONNAIRE_NAMES';
import { getBadgeStatus } from '../utils/getBadgeStatus';
import { QuestionnaireCompletionStatus } from '../utils/getQuestionnaireCompletionStatus';

interface Props {
  statusMap: Map<Survey, QuestionnaireCompletionStatus>;
}

export const UIQuestionnaireList = ({ statusMap }: Props) => {
  return (
    <ul data-testid="dashboard-list" className="divide-y divide-solid">
      <li key="header" className="py-3 flex justify-between space-x-2">
        <strong>Questionnaire Name</strong> <strong>Status</strong>
      </li>
      {Array.from(statusMap.entries()).map(([survey]) => {
        const { SurveyComponent, badgeText, badgeType } = getBadgeStatus(
          survey,
          statusMap,
        );
        if (
          badgeType === UIBadgeTypes.ERROR ||
          badgeType === UIBadgeTypes.WARN
        ) {
          return null;
        }

        // Null badgeType is returned from getBadgeStatus for non-background
        // assessments when background is incomplete, because there is no badge
        // to be displayed
        if (badgeType === null) {
          return (
            <li key={survey} className="py-3 flex justify-between space-x-2">
              <SurveyComponent data-testid={`survey-link-${survey}`}>
                {QUESTIONNAIRE_NAMES[survey] || survey}
              </SurveyComponent>
            </li>
          );
        }

        return (
          <li key={survey} className="py-3 flex justify-between space-x-2">
            <SurveyComponent
              data-testid={`survey-link-${survey}`}
              to={`/questionnaire/${survey}`}
              className="text-sm sm:text-base"
            >
              {QUESTIONNAIRE_NAMES[survey] || survey}
            </SurveyComponent>
            <UIBadge type={badgeType}>{badgeText}</UIBadge>
          </li>
        );
      })}
    </ul>
  );
};
