import { UIAlert, UIHeader } from '@team-and-tech/ui-components';

const TrainingPayWaiverHeader = () => {
  return (
    <>
      <UIHeader level="3">About the Training Pay Waiver below:</UIHeader>
      <UIAlert
        className="!mt-2"
        contentNode="You may elect to keep the active or inactive duty training pay you received from the military service department. However, to be legally entitled to keep your training pay, you must waive VA benefits for the number of days equal to the number of days for which you received training pay. In most instances, it will be to your advantage to waive your VA benefits and keep your training pay. If you waive VA benefits to receive training pay by checking the box below, VA will retroactively adjust your VA award to withhold benefits equal to the total number of training days waived and at the monthly rate in effect for the fiscal year period for which you received training pay. This action may result in an overpayment of compensation, which may be subject to collection.​"
        severity="info"
      />
    </>
  );
};

export default TrainingPayWaiverHeader;
