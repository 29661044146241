export const formatPhoneNumber = (v: string) => {
  if (!v) return '';
  const onlyNums = v.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) return onlyNums;
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 7)}`;
  }
  if (onlyNums.length >= 8) {
    if (onlyNums.slice(0, 1) === '1') {
      return `(${onlyNums.slice(1, 4)}) ${onlyNums.slice(
        4,
        7,
      )}-${onlyNums.slice(7)}`;
    } else {
      return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(
        3,
        6,
      )}-${onlyNums.slice(6)}`;
    }
  }
};
