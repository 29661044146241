import { StateType } from '@team-and-tech/ui-components';

export const getQuestionStateClasses = (state: StateType = 'default') => {
  if (state === 'default') {
    return '';
  }

  if (state === 'disabled') {
    return '';
  }

  if (state === 'error') {
    return 'pl-3 border-l-4 border-red-500';
  }

  return '';
};
