import { useMemo } from 'react';
import useSessionQuery from '../../api/queries/session';
import {
  QuestionnaireCompletionStatus,
  getQuestionnaireCompletionStatus,
} from './getQuestionnaireCompletionStatus';
import { Survey } from '../../questionnaires/types';
import { IdToQuestionnaireMap } from '../questionnaire-id-mapping';

export const useQuestionnaireStatus = () => {
  const { data: session, isLoading, isFetching, error } = useSessionQuery();

  const answerValues = session?.user?.answers?.values;
  const answerKeys = Object.keys(answerValues || {});

  const completedQuestionnaires = useMemo(
    () =>
      Object.values(Survey).filter(
        (surveyKey: Survey) =>
          getQuestionnaireCompletionStatus(surveyKey, answerValues) ===
            'complete' && surveyKey,
      ),
    [answerValues],
  );

  const hasCompletedBackgroundQuestionnaire = useMemo(() => {
    if (!answerValues) {
      return false;
    }

    return completedQuestionnaires.includes(Survey.Background);
  }, [answerValues, completedQuestionnaires]);

  const selectedQuestionnaires = useMemo(() => {
    return answerKeys
      .map((answerKey) => IdToQuestionnaireMap.get(answerKey))
      .filter((questionnaire): questionnaire is Survey =>
        Boolean(questionnaire),
      );
  }, [answerKeys]);

  const hasSelectedQuestionnaires = useMemo(() => {
    return selectedQuestionnaires.length > 0;
  }, [selectedQuestionnaires]);

  const selectedQuestionnaireStatusMap = useMemo(() => {
    const selected = new Map<Survey, QuestionnaireCompletionStatus>();

    if (hasCompletedBackgroundQuestionnaire) {
      selected.set(Survey.Background, 'complete');
    }

    selectedQuestionnaires.forEach((questionnaire) => {
      selected.set(
        questionnaire,
        getQuestionnaireCompletionStatus(questionnaire, answerValues),
      );
    });

    return selected;
  }, [
    answerValues,
    selectedQuestionnaires,
    hasCompletedBackgroundQuestionnaire,
  ]);

  const hasCompletedSelectedQuestionnaires = useMemo(() => {
    return (
      Array.from(selectedQuestionnaireStatusMap.values()).every(
        (status) => status === 'complete',
      ) && selectedQuestionnaires.length > 0
    );
  }, [selectedQuestionnaireStatusMap, selectedQuestionnaires]);

  return {
    session,
    hasCompletedBackgroundQuestionnaire,
    hasSelectedQuestionnaires,
    hasCompletedSelectedQuestionnaires,
    selectedQuestionnaires,
    selectedQuestionnaireStatusMap,
    completedQuestionnaires,
    queryStatus: {
      isLoading,
      isFetching,
      error,
    },
  };
};
