import UIQuestionnaireDashboardStatus from '../questionnaire-dashboard/UIQuestionnaireDashboardStatus';
import { Navigate, Outlet } from 'react-router-dom';
import { useSessionQuery } from '../api/queries/session';

const ResolveSlugAndMountVars = ({ slug }: { slug: string }) => {
  const { data: session, isLoading, error } = useSessionQuery(slug);

  if (isLoading) {
    return <UIQuestionnaireDashboardStatus loading={isLoading} error={error} />;
  }
  if (error) {
    return <UIQuestionnaireDashboardStatus error={error} />;
  }

  if (session) {
    return <Outlet />;
  }

  return <Navigate to="/" />;
};

export default ResolveSlugAndMountVars;
