import { screen, render } from '@testing-library/react';
import { InputType } from '../types';
import UIQuestion from '../UIQuestion';

describe('ServicePeriodHeader', () => {
  it('Should show "First" in header when question has matching id', () => {
    render(
      <>
        <UIQuestion
          question={{
            id: '7E533253-A160-4027-B357-C8C6C3C032B0',
            text: 'What color is the sky today?',
            input_type: InputType.RADIO,
            options: [
              { label: 'Yes', value: 'YES' },
              { label: 'No', value: 'NO' },
            ],
            questionFooter: 'ServicePeriodHeader',
          }}
        />
      </>,
    );
    expect(screen.getByText(/First Period of Service/)).toBeInTheDocument();
  });
  it('Should show "Second" in header when question has matching id', () => {
    render(
      <>
        <UIQuestion
          question={{
            id: '7FF1AE6A-BCFB-48CD-9474-01379BC61454',
            text: 'What color is the sky today?',
            input_type: InputType.RADIO,
            options: [
              { label: 'Yes', value: 'YES' },
              { label: 'No', value: 'NO' },
            ],
            questionFooter: 'ServicePeriodHeader',
          }}
        />
      </>,
    );
    expect(screen.getByText(/Second Period of Service/)).toBeInTheDocument();
  });
  it('Should show "Third" in header when question has matching id', () => {
    render(
      <>
        <UIQuestion
          question={{
            id: 'E35C6314-7EEF-44A5-9AD7-7BFF0051BF17',
            text: 'What color is the sky today?',
            input_type: InputType.RADIO,
            options: [
              { label: 'Yes', value: 'YES' },
              { label: 'No', value: 'NO' },
            ],
            questionFooter: 'ServicePeriodHeader',
          }}
        />
      </>,
    );
    expect(screen.getByText(/Third Period of Service/)).toBeInTheDocument();
  });
  it('Should show "Fourth" in header when question has matching id', () => {
    render(
      <>
        <UIQuestion
          question={{
            id: 'B931BF07-FEEA-4131-933B-28A575F75993',
            text: 'What color is the sky today?',
            input_type: InputType.RADIO,
            options: [
              { label: 'Yes', value: 'YES' },
              { label: 'No', value: 'NO' },
            ],
            questionFooter: 'ServicePeriodHeader',
          }}
        />
      </>,
    );
    expect(screen.getByText(/Fourth Period of Service/)).toBeInTheDocument();
  });
  it('Should show "Fifth" in header when question has matching id', () => {
    render(
      <>
        <UIQuestion
          question={{
            id: 'CB364CE9-E3BF-48E0-81A6-87DADEC491F3',
            text: 'What color is the sky today?',
            input_type: InputType.RADIO,
            options: [
              { label: 'Yes', value: 'YES' },
              { label: 'No', value: 'NO' },
            ],
            questionFooter: 'ServicePeriodHeader',
          }}
        />
      </>,
    );
    expect(screen.getByText(/Fifth Period of Service/)).toBeInTheDocument();
  });
});
