import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import {
  UIAlert,
  UIHeader,
  UIParagraph,
  UIStrong,
} from '@team-and-tech/ui-components';
import { Navigate } from 'react-router-dom';

import UIQuestionnaireDashboardStatus from './UIQuestionnaireDashboardStatus';
import { useMemo } from 'react';
import { UIQuestionnaireList } from './components/UIQuestionnaireList';
import { useQuestionnaireStatus } from './utils/useQuestionnaireStatus';
import { UINewUserDashboard } from './components/UINewUserDashboard';
import { UIQuestionnaireDashboardContainer } from './components/UIQuestionnaireDashboardContainer';
import { useDaVinciReportQuery } from '../api/queries/daVinciReport';

export default function UIQuestionnaireDashboard() {
  const {
    session,
    selectedQuestionnaireStatusMap,
    hasSelectedQuestionnaires,
    hasCompletedBackgroundQuestionnaire,
    hasCompletedSelectedQuestionnaires,
    queryStatus: { isLoading, isFetching, error },
  } = useQuestionnaireStatus();

  const {
    data: daVinciReport,
    isLoading: isLoadingDaVinciReport,
    error: daVinciReportError,
  } = useDaVinciReportQuery({
    retry: false,
    enabled: hasCompletedBackgroundQuestionnaire,
  });

  const predictedClaimsCount = useMemo(() => {
    const count = daVinciReport?.predicted?.length || 0;

    if (count === 1) {
      return '1 potential new condition';
    }

    return `${count} potential new conditions`;
  }, [daVinciReport]);

  if (
    isLoading ||
    isFetching ||
    (isLoadingDaVinciReport && hasCompletedBackgroundQuestionnaire)
  ) {
    return (
      <UIQuestionnaireDashboardStatus
        loading={true}
        loadingMessage={
          isFetching ? 'Updating your user session...' : undefined
        }
      />
    );
  }
  if (error || daVinciReportError) {
    return <UIQuestionnaireDashboardStatus error={error} />;
  }

  if (!hasCompletedBackgroundQuestionnaire) {
    return (
      <UIQuestionnaireDashboardContainer
        session={session}
        header={
          <>
            <UIHeader level="1">{`Hi, ${session?.user?.first_name} ${session?.user?.last_name}`}</UIHeader>
            <UIParagraph>Welcome to Trajector!</UIParagraph>
          </>
        }
      >
        <UINewUserDashboard />
      </UIQuestionnaireDashboardContainer>
    );
  }

  if (!hasSelectedQuestionnaires) {
    return <Navigate to="/questionnaire/select" />;
  }

  if (hasCompletedSelectedQuestionnaires) {
    return <Navigate to="/questionnaire/success" />;
  }

  return (
    <UIQuestionnaireDashboardContainer
      session={session}
      header={
        <>
          <UIHeader level="1">Your Questionnaires</UIHeader>
        </>
      }
    >
      <div className="space-y-4">
        <div className="grid grid-flow-row lg:grid-flow-col gap-4">
          <section className="space-y-2">
            <UIParagraph>
              Complete your condition questionnaires so we can generate your
              personal Conditions Report.
            </UIParagraph>
            <UIParagraph>
              This report will show you a list of potential NEW VA-ratable
              conditions that you may be able to file for!
            </UIParagraph>
            <UIAlert
              severity="info"
              contentNode="Need a little more time? Use the link we sent you via email to
                pick up where you left off. You must fully complete a
                questionnaire for it to be saved."
            />
          </section>

          {daVinciReport && daVinciReport?.predicted?.length > 0 && (
            <section className="flex flex-col items-center justify-center border border-brand-blue-100 rounded-md bg-brand-green-50 p-4 lg:px-6 lg:py-8 min-w-[226px]">
              <DocumentDuplicateIcon className="text-brand-green-200 mb-2 w-12 h-12 lg:mb-4" />
              <UIParagraph>We've found</UIParagraph>
              <UIParagraph>
                <UIStrong>{predictedClaimsCount}</UIStrong>
              </UIParagraph>
              <UIParagraph>for you already! Keep going!</UIParagraph>
            </section>
          )}
        </div>

        <UIQuestionnaireList statusMap={selectedQuestionnaireStatusMap} />
      </div>
    </UIQuestionnaireDashboardContainer>
  );
}
