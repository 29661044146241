import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getClient } from '../client';
import {
  User,
  UserSurveysSession,
} from '@team-and-tech/trajector-cloud-api-client';
import useStore from '../../app/store';
import useCachedToken from '../../sessionCache/useCachedToken';

// PR#500
// These answers are pulled in on the User object, having been
// pre-collected by the landing page. We don't want to modify
// or create mismatched data.
export const USER_DATA_QUESTION_IDS = {
  name: 'DDA7655A-CEDF-4806-B747-F88DBFA5395E',
  phone_number: '5C7D4EFB-87FF-44B9-9D54-80755211DA91',
  email: 'AFA9C8CB-C959-4D47-BCD7-A63BA6724A5E',
};

// PR#500
// Taking the User information from the session
// response and loading them in as answers
const mapWithUserData = (userData: User) =>
  new Map<string, string>([
    [
      USER_DATA_QUESTION_IDS.name,
      `${userData.first_name} ${userData.last_name}`,
    ],
    [USER_DATA_QUESTION_IDS.phone_number, userData.phone_number as string],
    [USER_DATA_QUESTION_IDS.email, userData.email],
  ]);

export const useSessionQuery = (
  slug?: string,
  options?: UseQueryOptions<UserSurveysSession>,
) => {
  const { answers: answersStore, setAnswers, setToken, token } = useStore();
  const { setToken: setCachedToken } = useCachedToken();

  const getUserSession = (token: string) => async () => {
    const client = getClient();
    const response = await client.getUserSurveysSession({
      headers: {
        'X-Trajector-Invite': token,
      },
    });
    if (response.status === 200 && response.data.user) {
      // check for one of the user data values to make sure they've been set already
      if (!answersStore.has(USER_DATA_QUESTION_IDS.email)) {
        // Loads the user data into the answers map to prevent re-collecting user data
        // on background survey
        setAnswers((prevAnswers) => {
          return new Map([
            ...Array.from(prevAnswers),
            ...Array.from(mapWithUserData(response.data.user)),
          ]);
        });
      }
      setToken(token);
      // A successful response means a valid token, so we store it in sessionStorage on the browser. This sessionStorage cache consumed by the store when it initializes the token useState on reloads.
      setCachedToken(token);
    }
    return response.data;
  };

  return useQuery<UserSurveysSession>(
    ['session'],
    getUserSession(slug || token || ''),
    {
      staleTime: 600000,
      ...options,
    },
  );
};

export default useSessionQuery;
