import { Transition, Dialog } from '@headlessui/react';
import { UIButton, UIHeader, UIParagraph } from '@team-and-tech/ui-components';
import { Fragment, useState } from 'react';

const ToolboxTermsOfService = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="!-mb-4">
      <UIHeader level="3">
        <span className="text-red-500 mr-1">*</span>License Agreement and Terms
        of Service 
      </UIHeader>
      <UIParagraph>
        You must agree to the{' '}
        <span
          className="text-primary underline cursor-pointer"
          onClick={() => setModalOpen(true)}
        >
          limited license and terms of service agreement
        </span>{' '}
        for Atomic Forms to submit your answers.
      </UIParagraph>
      <Transition.Root show={modalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setModalOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                  <div className="mt-3 sm:mt-0">
                    <Dialog.Title
                      as="h2"
                      className="text-xl font-medium leading-6 text-gray-900 mb-4"
                    >
                      License Agreement and Terms of Service 
                    </Dialog.Title>
                    <div className="sm:ml-1">
                      <UIParagraph className="mt-2">
                        Atomic Forms is a service (i.e., website and mobile
                        application) provided by Trajector Medical, LLC, to
                        allow self-completion and self-filing of Veterans
                        Affairs (VA) forms and supporting medical evidence.
                        Atomic Forms is in no way offered or sponsored by or
                        otherwise associated with the VA. No assistance is
                        provided to you in completing or preparing any VA forms
                        and you accept all risk associated with inaccurate or
                        incomplete entries. You are exclusively responsible for
                        the accuracy and completeness of any information you
                        enter on this site.
                      </UIParagraph>
                      <UIParagraph className="mt-2">
                        You may not use this service until you have read this
                        agreement and indicated your unconditional acceptance of
                        it by checking the box below. If you do not accept this
                        agreement, you must stop using this service.
                      </UIParagraph>
                      <UIParagraph className="mt-2">
                        Atomic Forms is not a covered entity under HIPAA. For a
                        description of your privacy rights and Atomic Forms’
                        security obligations see the Notice of Privacy Practices
                        described below.
                      </UIParagraph>
                      <hr className="mt-2" />
                      <UIParagraph className="mt-2">
                        You agree that Atomic Forms is not acting as your
                        representative, agent, fiduciary, or advocate in
                        connection with your use of the self-services. You may
                        only use these services for your own VA claim. Atomic
                        Forms has no liability or responsibility for damages or
                        claims relating to your misuse of VA forms.
                      </UIParagraph>
                      <UIParagraph className="mt-2">
                        Atomic Forms cannot guarantee that the VA will accept or
                        process your claim for benefits submitted through this
                        platform. You are responsible for confirming the VA’s
                        receipt of all submissions through this platform and for
                        meeting all applicable filing deadlines.
                      </UIParagraph>
                      <UIParagraph className="mt-2">
                        Use of these services is at your own risk and you agree
                        to hold Atomic Forms harmless in the event of any errors
                        or compromise of data. In any event, liability is
                        strictly limited to the amount of fees you have paid to
                        Atomic Forms, if any.
                      </UIParagraph>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4">
                    <div className="flex flex-col space-y-1 text-center">
                      <UIButton
                        onClick={() => setModalOpen(false)}
                        use="tertiary"
                        className="flex-grow-0"
                      >
                        Close
                      </UIButton>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default ToolboxTermsOfService;
