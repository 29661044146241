import { UIDivider, UIHeader } from '@team-and-tech/ui-components';
import { Question } from '../types';

interface ServicePeriodHeaderProps {
  question: Question;
  'data-testid'?: string;
}

const ServicePeriodHeader = ({
  question,
  'data-testid': testId,
}: ServicePeriodHeaderProps) => {
  let periodDescription = '';
  switch (question?.id) {
    case '7E533253-A160-4027-B357-C8C6C3C032B0':
      periodDescription = 'First';
      break;
    case '7FF1AE6A-BCFB-48CD-9474-01379BC61454':
      periodDescription = 'Second';
      break;
    case 'E35C6314-7EEF-44A5-9AD7-7BFF0051BF17':
      periodDescription = 'Third';
      break;
    case 'B931BF07-FEEA-4131-933B-28A575F75993':
      periodDescription = 'Fourth';
      break;
    case 'CB364CE9-E3BF-48E0-81A6-87DADEC491F3':
      periodDescription = 'Fifth';
      break;
  }

  return (
    <div data-testid={testId}>
      <UIHeader level="1" className="!mb-0">
        {periodDescription} Period of Service
      </UIHeader>
      <UIDivider className="!mt-2" />
    </div>
  );
};

export default ServicePeriodHeader;
