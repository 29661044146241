import { UITextArea } from '@team-and-tech/ui-components';
import { Dispatch, SetStateAction } from 'react';
import { Question } from '../questions/types';
interface Props {
  answer: any;
  question: Question;
  setAnswer: Dispatch<SetStateAction<any>>;
  onBlur?: (e: any) => void;
}

export default function UIAnswerTextArea({
  answer = '',
  question,
  setAnswer,
  onBlur,
}: Props) {
  return (
    <UITextArea
      name={question.id}
      data-testid={question.id}
      onChange={(e: any) => setAnswer(e.target.value)}
      value={answer}
      onBlur={onBlur}
    />
  );
}
