import { Survey } from '../questionnaires/types';

import backgroundQuestionnaire from '../questionnaires/data/background-questionnaire.json';
import cardiacRespiratoryQuestionnaire from '../questionnaires/data/cardiac-respiratory-questionnaire.json';
import endocrineImmuneQuestionnaire from '../questionnaires/data/endocrine-immune-questionnaire.json';
// import eyesBloodMedicationQuestionnaire from '../questionnaires/data/eyes-blood-medication-questionnaire.json';
import genderQuestionnaire from '../questionnaires/data/gender-questionnaire.json';
import giSkinQuestionnaire from '../questionnaires/data/gi-skin-questionnaire.json';
import musculoskeletalQuestionnaire from '../questionnaires/data/musculoskeletal-questionnaire.json';
import nervousCancerQuestionnaire from '../questionnaires/data/nervous-cancer-questionnaire.json';
import psychEntQuestionnaire from '../questionnaires/data/psych-ent-questionnaire.json';

export const QUESTIONNAIRE_NAMES = {
  [Survey.Background]: backgroundQuestionnaire.title,
  [Survey.PsychEnt]: psychEntQuestionnaire.title,
  [Survey.Musculoskeletal]: musculoskeletalQuestionnaire.title,
  [Survey.EndocrineImmune]: endocrineImmuneQuestionnaire.title,
  [Survey.NervousCancer]: nervousCancerQuestionnaire.title,
  // [Survey.EyesBloodMedication]: eyesBloodMedicationQuestionnaire.title,
  [Survey.Gender]: genderQuestionnaire.title,
  [Survey.GiSkin]: giSkinQuestionnaire.title,
  [Survey.CardiacRespiratory]: cardiacRespiratoryQuestionnaire.title,
};
