import {
  ClockIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline';
import { Survey } from '@team-and-tech/trajector-cloud-api-client';
import {
  UIButton,
  UIHeader,
  UIParagraph,
  UIStrong,
} from '@team-and-tech/ui-components';
import { Link } from 'react-router-dom';

export const UINewUserDashboard = () => {
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col space-y-2">
        <UIHeader level="2">
          Could there be more VA benefits out there for you?
        </UIHeader>
        <UIHeader level="4" className="!mb-2">
          Learn more now!
        </UIHeader>
        {/* Old content removed by legal from a round of feedback. Keeping around for reference */}
        {/* <div className="flex flex-row items-start space-x-2 mb-2">
          <div className="p-1 rounded-full bg-primary/10">
            <BanknotesIcon
              className="h-7 w-7 text-primary"
              aria-hidden="true"
            />
          </div>
          <UIParagraph className="my-1">
            If you succeed, you could start receiving{' '}
            <UIStrong>$1,200-$36,000 PER YEAR from the VA.</UIStrong>
          </UIParagraph>
        </div> */}
        <div className="flex flex-row items-start space-x-2">
          <div className="p-1 rounded-full bg-primary/10">
            <ClockIcon className="h-6 w-6 text-primary" aria-hidden="true" />
          </div>
          <UIParagraph className="my-1">
            Answer some simple questions about <UIStrong>you</UIStrong>,{' '}
            <UIStrong>your symptoms</UIStrong>, and{' '}
            <UIStrong>your military service</UIStrong>. It takes just{' '}
            <UIStrong>15 minutes</UIStrong>.
          </UIParagraph>
        </div>
        <div className="flex flex-row items-start space-x-2">
          <div className="p-1 rounded-full bg-primary/10">
            <DocumentDuplicateIcon
              className="h-6 w-6 text-primary"
              aria-hidden="true"
            />
          </div>
          <UIParagraph className="my-1">
            Our advanced analysis will generate your{' '}
            <UIStrong>exclusive Conditions Report</UIStrong>.
          </UIParagraph>
        </div>
        <div className="flex flex-row items-start space-x-2">
          <div className="p-1 rounded-full bg-primary/10">
            <DocumentTextIcon
              className="h-6 w-6 text-primary"
              aria-hidden="true"
            />
          </div>
          <UIParagraph className="my-1">
            View potential <UIStrong>VA-ratable conditions</UIStrong> in your
            report!
          </UIParagraph>
        </div>
      </div>
      {/* <div className="flex flex-col space-y-2">
        <UIHeader level="2">Get Started</UIHeader>
        <UIParagraph className="my-1">
          <UIStrong>Start</UIStrong> by answering questions about your symptoms,
          your military service, and any medical treatments you've had.
        </UIParagraph>
        <UIParagraph className="my-1">
          <UIStrong>Next</UIStrong>, we'll show you a list of your potential
          health conditions.
        </UIParagraph>
        <UIParagraph className="my-1">
          <UIStrong>Then</UIStrong>, you'll learn more about your options with
          Trajector Medical, including full service, lawyer help, and our{' '}
          <UIStrong>free</UIStrong> Veteran's Toolbox.
        </UIParagraph>
      </div> */}

      <div className="flex justify-center mt-0">
        <Link to={`/questionnaire/${Survey.Background}`}>
          <UIButton className="w-80">Get Started</UIButton>
        </Link>
      </div>
    </div>
  );
};
