import { Transition, Dialog } from '@headlessui/react';
import { UIButton, UIHeader, UIParagraph } from '@team-and-tech/ui-components';
import { Fragment, useState } from 'react';

const DaVinciTermsOfService = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="!-mb-4">
      <UIHeader level="3">
        <span className="text-red-500 mr-1">*</span>License Agreement and Terms
        of Service 
      </UIHeader>
      <UIParagraph>
        You must agree to the{' '}
        <span
          onClick={() => setModalOpen(true)}
          className="underline cursor-pointer text-primary"
        >
          limited license and terms of service agreement
        </span>{' '}
        for Trajector's DaVinci Medical Screener to submit your answers.
      </UIParagraph>
      <Transition.Root show={modalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setModalOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                  <div className="mt-3 sm:mt-0">
                    <Dialog.Title
                      as="h2"
                      className="text-xl font-medium leading-6 text-gray-900 mb-4"
                    >
                      License Agreement and Terms of Service 
                    </Dialog.Title>
                    <div className="sm:ml-1">
                      <UIParagraph className="mt-2">
                        Trajector’s Preliminary Medical Screening Tool (Da Vinci
                        Medical Screener) is a service (i.e., website and mobile
                        application) that has been designed to collect
                        self-reported medical symptoms from a user and generate
                        a tentative approximation of the medical conditions that
                        match with the Department of Veterans Affairs’ (VA’s)
                        medical evaluation and rating standards contained in the
                        Schedule of Disabilities in Title 38 of the Code of
                        Federal Regulations and other medical standards. This
                        approximation is not based on the experience of all
                        Veteran’s who have applied for benefits but rather on
                        proprietary information we have developed. Trajector’s
                        Da Vinci Medical Screener does not diagnose health
                        conditions and does not result in a medical opinion. Its
                        results should not be used as evidence in support of a
                        VA claim. The purpose of Trajector’s Da Vinci Medical
                        Screener is to identify potential health conditions for
                        further review and consideration by a Veteran and the
                        veteran’s provider, accredited VA representative or
                        Veterans Serivce Officer, or attorney. Trajector’s Da
                        Vinici Medical Screener is in no way offered or
                        sponsored by or otherwise associated with the VA. No
                        assistance is provided to you in developing medical
                        evidence and you accept all risk associated with
                        inaccurate or incomplete Da Vinci report. You are
                        exclusively responsible for the accuracy and
                        completeness of any information you enter on this site.
                      </UIParagraph>
                      <UIParagraph className="mt-2">
                        You may not use this service until you have read this
                        agreement and indicated your unconditional acceptance of
                        it by checking the box below. If you do not accept this
                        agreement, you must stop using this service.
                      </UIParagraph>
                      <UIParagraph className="mt-2">
                        DaVinci is not a covered entity under HIPAA. The Da
                        Vinci screening tool will invite you to enter sensitive
                        personal information for the purposes described herein.
                        We take your privacy and the security of your data
                        seriously. For a description of your privacy rights and
                        Trajector’s Da Vinci Medical Screener’s security
                        obligations, see the Privacy Policy described below.
                      </UIParagraph>
                      <hr className="mt-2" />
                      <UIParagraph className="mt-2">
                        You agree that Trajector’s Da Vinci Medical Screener is
                        not acting as a medical provider, medical analyst, or
                        medical consultant and is not your representative,
                        agent, fiduciary, or advocate in connection with your
                        use of these self-services. You may only use these
                        services for the purpose of preliminarily identifying
                        your own health conditions. Trajector has no liability
                        or responsibility for damages or claims relating to your
                        misuse of the Trajector Da Vinci Medical Screener.
                      </UIParagraph>
                      <UIParagraph className="mt-2">
                        Trajector’s Da Vinci Medical Screener cannot guarantee
                        that the VA will accept or process a claim for benefits.
                        You are responsible for obtaining professional
                        assistance and guidance in interpreting the results of
                        this screening. 
                      </UIParagraph>
                      <UIParagraph className="mt-2">
                        Use of these services is at your own risk and you agree
                        to hold Trajector harmless in the event of any errors or
                        compromise of data. In any event, liability is strictly
                        limited to the amount of fees you have paid to
                        Trajector, if any.
                      </UIParagraph>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4">
                    <div className="flex flex-col space-y-1 text-center">
                      <UIButton
                        onClick={() => setModalOpen(false)}
                        use="tertiary"
                        className="flex-grow-0"
                      >
                        Close
                      </UIButton>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default DaVinciTermsOfService;
