import { CheckIcon } from '@heroicons/react/24/outline';
import {
  UIHeader,
  UIButton,
  UIContainer,
  UIParagraph,
  UIStrong,
} from '@team-and-tech/ui-components';
import { Link, useSearchParams } from 'react-router-dom';
import useStore from '../../app/store';

import UIQuestionnaireDashboardStatus from '../../questionnaire-dashboard/UIQuestionnaireDashboardStatus';
import { useDaVinciReportQuery } from '../../api/queries/daVinciReport';
import { useMemo } from 'react';
import { useQuestionnaireStatus } from '../../questionnaire-dashboard/utils/useQuestionnaireStatus';

const UIQuestionnaireSuccessPage = () => {
  const { token } = useStore();

  const {
    hasCompletedSelectedQuestionnaires,
    hasCompletedBackgroundQuestionnaire,
    queryStatus: { isFetching, error },
  } = useQuestionnaireStatus();

  const {
    data: daVinciReport,
    error: daVinciReportError,
    isFetching: isFetchingReport,
  } = useDaVinciReportQuery({
    enabled: hasCompletedBackgroundQuestionnaire,
  });

  const [params] = useSearchParams();

  const hasPredictedClaims = useMemo(
    () => daVinciReport && daVinciReport?.predicted?.length > 0,
    [daVinciReport],
  );

  const claimsCountText = useMemo(() => {
    if (daVinciReport?.predicted?.length === 1) {
      return '1 potential new condition';
    }

    return `${daVinciReport?.predicted?.length} potential new conditions`;
  }, [daVinciReport]);

  const shouldShowClaimsCount =
    hasPredictedClaims && params.get('mode') !== 'completed_selection';

  const ctaText = useMemo(() => {
    const mode = params.get('mode');

    if (mode === 'completed_selection') {
      return 'Your Questionnaire List';
    }

    if (hasCompletedSelectedQuestionnaires) {
      return 'See My Condition Report';
    }

    return 'Uncover More';
  }, [hasCompletedSelectedQuestionnaires, params]);

  if (isFetching || isFetchingReport) {
    const loadingMessage = () => {
      if (isFetching) {
        return 'Updating your user session...';
      }
      if (isFetchingReport) {
        return 'Checking for potential new conditions...';
      }
      return undefined;
    };
    return (
      <UIQuestionnaireDashboardStatus
        loading={true}
        loadingMessage={loadingMessage()}
      />
    );
  }
  if (error || daVinciReportError) {
    return <UIQuestionnaireDashboardStatus error={error} />;
  }

  return (
    <UIContainer verticalAlignment="center">
      <div data-testid="success-page" className="text-center">
        <div className="mx-auto flex items-center justify-center h-20 w-20 rounded-full bg-green-100 my-3">
          <CheckIcon className="h-10 w-10 text-green-600" aria-hidden="true" />
        </div>
        {hasCompletedSelectedQuestionnaires ? (
          <UIHeader level="3" className="mb-4">
            Great! You're ready to see your Condition Report.
          </UIHeader>
        ) : (
          <UIHeader level="3" className="mb-4">
            Great! We received your answers.
          </UIHeader>
        )}

        {shouldShowClaimsCount ? (
          <>
            <UIParagraph className="text-gray-500">
              We found <UIStrong>{claimsCountText}</UIStrong>!
            </UIParagraph>
            {!hasCompletedSelectedQuestionnaires && (
              <UIParagraph className="text-gray-500">Keep going!</UIParagraph>
            )}
          </>
        ) : (
          <>
            <UIParagraph className="text-gray-500">
              We’re searching for new, potentially ratable conditions for you.
            </UIParagraph>
            <UIParagraph className="text-gray-500">Keep going!</UIParagraph>
          </>
        )}
        <div className="flex flex-row space-x-3 my-3 justify-center">
          <Link
            to={
              hasCompletedSelectedQuestionnaires
                ? '/claims-report'
                : `/invite/${token}`
            }
          >
            <UIButton use="primary">{ctaText}</UIButton>
          </Link>
        </div>
      </div>
    </UIContainer>
  );
};

export default UIQuestionnaireSuccessPage;
