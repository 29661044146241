// import { Survey } from '@team-and-tech/trajector-cloud-api-client';

export const Survey = {
  Background: 'background',
  CardiacRespiratory: 'cardiac-respiratory',
  EndocrineImmune: 'endocrine-immune',
  Gender: 'gender',
  GiSkin: 'gi-skin',
  Musculoskeletal: 'musculoskeletal',
  NervousCancer: 'nervous-cancer',
  PsychEnt: 'psych-ent',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export declare type Survey = (typeof Survey)[keyof typeof Survey];

export type Page = {
  id: string;
  header: string;
  headerText?: string;
  footerText?: string;
  questionIds: string[];
};

export type Questionnaire = {
  id: Survey;
  pages: Page[];
  title: string;
  description?: string;
};
