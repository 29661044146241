import { DefaultApi } from '@team-and-tech/trajector-cloud-api-client';
import { getEnv } from './getEnv';

let client: DefaultApi;

const PROD_PATH = process.env.REACT_APP_PROD_SURVEY_API_URL;
const BASE_PATH = 'http://localhost:8000/survey/v1';
const STG_PATH = process.env.REACT_APP_STAGING_SURVEY_API_URL;

export const getClient = () => {
  if (client) return client;
  const isStg = getEnv() === 'staging';
  const isProd = getEnv() === 'production';
  const basePath = isStg ? STG_PATH : isProd ? PROD_PATH : BASE_PATH;
  client = new DefaultApi(undefined, basePath);
  return client;
};
