import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import {
  UIContainer,
  UIHeader,
  UIParagraph,
  UIButton,
} from '@team-and-tech/ui-components';

const NoInviteLink = () => {
  return (
    <UIContainer verticalAlignment="center">
      <div className="flex flex-col space-y-2">
        <div className="mx-auto flex items-center justify-center h-24 w-24 rounded-full bg-yellow-100 my-3">
          <ExclamationCircleIcon
            className="h-16 w-16 text-yellow-600"
            aria-hidden="true"
          />
        </div>
        <UIHeader level="1" className="text-center">
          No invitation link
        </UIHeader>
        <UIParagraph className="text-center">
          You need a personal invitation link to access this application.
        </UIParagraph>
        <div className="flex flex-row space-x-3 my-3 justify-center">
          <>
            {/* <a
              href="mailto:support@trajector.com"
              target="_blank"
              rel="noreferrer"
            >
              <UIButton use="secondary">Email us for help</UIButton>
            </a> */}
            <a href="https://trajector.com" target="_blank" rel="noreferrer">
              <UIButton use="primary">Trajector homepage</UIButton>
            </a>
          </>
        </div>
      </div>
    </UIContainer>
  );
};

export default NoInviteLink;
