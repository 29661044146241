import backgroundQuestionnaire from './data/background-questionnaire.json';
import backgroundQuestions from './data/background-questions.json';
import cardiacRespiratoryQuestionnaire from './data/cardiac-respiratory-questionnaire.json';
import cardiacRespiratoryQuestions from './data/cardiac-respiratory-questions.json';
import endocrineImmuneQuestionnaire from './data/endocrine-immune-questionnaire.json';
import endocrineImmuneQuestions from './data/endocrine-immune-questions.json';
// import eyesBloodMedicationQuestionnaire from './data/eyes-blood-medication-questionnaire.json';
// import eyesBloodMedicationQuestions from './data/eyes-blood-medication-questions.json';
import genderQuestionnaire from './data/gender-questionnaire.json';
import genderQuestions from './data/gender-questions.json';
import giSkinQuestionnaire from './data/gi-skin-questionnaire.json';
import giSkinQuestions from './data/gi-skin-questions.json';
import musculoskeletalQuestionnaire from './data/musculoskeletal-questionnaire.json';
import musculoskeletalQuestions from './data/musculoskeletal-questions.json';
import nervousCancerQuestionnaire from './data/nervous-cancer-questionnaire.json';
import nervousCancerQuestions from './data/nervous-cancer-questions.json';
import psychEntQuestionnaire from './data/psych-ent-questionnaire.json';
import psychEntQuestions from './data/psych-ent-questions.json';
import toolboxQuestionnaire from './data/toolbox-questionnaire.json';
import toolboxQuestions from './data/toolbox-questions.json';
import { Questionnaire } from './types';
import { Question } from '../questions/types';
import { Survey } from '../questionnaires/types';

const byId = (acc: Map<Question['id'], Question>, item: Question) => {
  acc.set(item.id, item);
  return acc;
};

const SURVEYS_BY_ID = {
  [Survey.Background]: {
    questionnaire: backgroundQuestionnaire,
    questions: backgroundQuestions,
  },
  [Survey.CardiacRespiratory]: {
    questionnaire: cardiacRespiratoryQuestionnaire,
    questions: cardiacRespiratoryQuestions,
  },
  [Survey.EndocrineImmune]: {
    questionnaire: endocrineImmuneQuestionnaire,
    questions: endocrineImmuneQuestions,
  },
  // [Survey.EyesBloodMedication]: {
  //   questionnaire: eyesBloodMedicationQuestionnaire,
  //   questions: eyesBloodMedicationQuestions,
  // },
  [Survey.Gender]: {
    questionnaire: genderQuestionnaire,
    questions: genderQuestions,
  },
  [Survey.GiSkin]: {
    questionnaire: giSkinQuestionnaire,
    questions: giSkinQuestions,
  },
  [Survey.Musculoskeletal]: {
    questionnaire: musculoskeletalQuestionnaire,
    questions: musculoskeletalQuestions,
  },
  [Survey.NervousCancer]: {
    questionnaire: nervousCancerQuestionnaire,
    questions: nervousCancerQuestions,
  },
  [Survey.PsychEnt]: {
    questionnaire: psychEntQuestionnaire,
    questions: psychEntQuestions,
  },
  // Additional questionnaire for toolbox path, has no connection with DaVinci legacy deluge scripts
  toolbox: {
    questionnaire: toolboxQuestionnaire,
    questions: toolboxQuestions,
  },
};

const getQuestionnaireDataById: (id: Survey | 'toolbox') => {
  questionnaire: Questionnaire;
  questionsMap: Map<Question['id'], Question>;
} = (id) => {
  if (!SURVEYS_BY_ID[id]) {
    throw new Error(`Questionnaire data not found for ${id}`);
  }

  const unfilteredQuestionsMap = (SURVEYS_BY_ID[id].questions as any[]).reduce(
    byId,
    new Map<Question['id'], Question>(),
  );

  // The questions list contains all original davinci questions, we pare that down
  // with the questionIds arrays for each page in the questionnaire definitions.
  // We filter against those ids to determine the map of questions to return. Otherwise
  // the questionnaire component will require answers to questions that aren't displayed
  // in the questionnaire definition.
  const questionsInQuestionnaire = (
    SURVEYS_BY_ID[id].questionnaire as Questionnaire
  ).pages.reduce<string[]>((acc, page) => [...acc, ...page.questionIds], []);

  const filteredQuestionsMap: Map<Question['id'], Question> = new Map(
    [...unfilteredQuestionsMap].filter((item: [Question['id'], Question]) =>
      questionsInQuestionnaire.includes(item[0]),
    ),
  );

  return {
    questionnaire: SURVEYS_BY_ID[id].questionnaire as Questionnaire,
    // https://github.com/microsoft/TypeScript/issues/44063
    questionsMap: filteredQuestionsMap,
  };
};

export default getQuestionnaireDataById;
