export const NERVOUS_CANCER_ANSWERS = [
  [
    '70CC67C5-4FD9-47FD-8D92-C965C7FDDEA3',
    ['PERIPHERAL_NEUROPATHY_NUMBNESS_IN_HANDS_OR_FEET_RIGHT'],
  ],
  [
    '7F64D679-9177-4C10-829F-016E0FA7FB7D',
    [
      'I_NEVER_APPLIED_FOR_THIS_AND_I_DID_NOT_HAVE_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
      'I_NEVER_APPLIED_FOR_THIS_BUT_I_HAD_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
    ],
  ],
  ['AEFC8C99-AA9E-453B-B55D-49840C679C95', ['KIDNEY_OR_BLADDER_CANCER']],
  [
    '80086B2E-D940-4433-A894-7775053D8755',
    [
      'I_NEVER_APPLIED_FOR_THIS_AND_I_DID_NOT_HAVE_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
      'I_NEVER_APPLIED_FOR_THIS_BUT_I_HAD_ISSUES_OR_MEDICAL_TREATMENT_FOR_THIS_WHILE_ON_ACTIVE_DUTY',
    ],
  ],
];
