import davinciOutputMapping from './davinci-output-mapping.json';

const mapping: { [key: string]: string } = davinciOutputMapping;

export const cleanAndDedupeClaims = (claims: string[]) => {
  const cleanedClaims = claims
    .map((claim: string) => mapping[claim])
    .filter((claim) => claim);
  const dedupedClaims = cleanedClaims.filter((c: string, index: number) => {
    return cleanedClaims.indexOf(c) === index;
  });
  return dedupedClaims;
};
