import { UIDateInput, DateInputState } from '@team-and-tech/ui-components';
import { Dispatch, SetStateAction } from 'react';
import { Question } from '../questions/types';
interface Props {
  answer: DateInputState | string;
  question: Question;
  setAnswer: Dispatch<SetStateAction<any>>;
  onBlur?: (e: any) => void;
}

export default function UIAnswerDate({
  answer = '',
  question,
  setAnswer,
  onBlur,
}: Props) {
  return (
    <UIDateInput
      name={question.id}
      data-testid={question.id}
      onChange={(v) => {
        if (v.error && typeof v.dateString === 'undefined') {
          return setAnswer(null);
        }
        return setAnswer(v?.dateString || '');
      }}
      value={answer}
      onBlur={onBlur}
    />
  );
}
