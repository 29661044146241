const LoadingSpinner = () => (
  <div
    className={`mx-auto flex items-center justify-center h-40 w-40 rounded-full bg-blue-50`}
  >
    <svg
      className="animate-spin h-36 w-36"
      width="180"
      height="180"
      viewBox="0 0 180 180"
      fill="none"
    >
      <path
        className="fill-blue-400"
        d="M157 90H177C177 41.9512 138.049 3 90 3C41.9512 3 3 41.9512 3 90C3 120.516 18.711 147.362 42.4855 162.891L53.4084 146.135C35.0993 134.175 23 113.501 23 90C23 52.9969 52.9969 23 90 23C127.003 23 157 52.9969 157 90Z"
        fill="#D9D9D9"
      />
    </svg>
  </div>
);

export default LoadingSpinner;
