import { UserSurveysSession } from '@team-and-tech/trajector-cloud-api-client';
import { UIContainer } from '@team-and-tech/ui-components';
import { useParams } from 'react-router-dom';

interface Props {
  session?: UserSurveysSession;
  header?: React.ReactNode;
  children: React.ReactNode;
}

export const UIQuestionnaireDashboardContainer = ({
  header,
  children,
}: Props) => {
  const { slug } = useParams();
  return (
    <UIContainer data-testid={`dashboard-${slug}`} header={header}>
      {children}
    </UIContainer>
  );
};
