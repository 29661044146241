import { XMarkIcon } from '@heroicons/react/24/outline';
import {
  UIButton,
  UICode,
  UIContainer,
  UIHeader,
  UIParagraph,
} from '@team-and-tech/ui-components';
import LoadingSpinner from '../svg/LoadingSpinner';

interface UIQuestionnaireDashboardStatusProps {
  loading?: boolean;
  error?: any;
  errorMessage?: string;
  loadingMessage?: string;
}

const UIQuestionnaireDashboardStatus = ({
  loading,
  error,
  errorMessage,
  loadingMessage,
}: UIQuestionnaireDashboardStatusProps) => {
  if (loading) {
    return (
      <UIContainer verticalAlignment="center">
        <div data-testid="dashboard-loading" className="text-center">
          <LoadingSpinner />
          <UIHeader level="3" className="my-4">
            {loadingMessage || 'Loading...'}
          </UIHeader>
        </div>
      </UIContainer>
    );
  }
  if (error) {
    return (
      <UIContainer verticalAlignment="center">
        <div data-testid="dashboard-error" className="text-center">
          <div className="mx-auto flex items-center justify-center h-40 w-40 rounded-full bg-red-100 my-3">
            <XMarkIcon className="h-24 w-24 text-red-600" aria-hidden="true" />
          </div>
          <UIHeader level="3" className="mb-2">
            Sorry! Something went wrong.
          </UIHeader>
          {errorMessage && (
            <UIParagraph className={error ? 'mb-3' : 'mb-6'}>
              {errorMessage}
            </UIParagraph>
          )}
          {error && (
            <UIParagraph className="mb-6">
              <UICode>{error?.message}</UICode>
            </UIParagraph>
          )}
          <div className="flex flex-row space-x-3 my-3 justify-center">
            {/* <a
              href="mailto:support@trajector.com"
              target="_blank"
              rel="noreferrer"
            >
              <UIButton use="secondary">Email us for help</UIButton>
            </a> */}
            <a href="https://trajector.com" target="_blank" rel="noreferrer">
              <UIButton use="primary">Trajector homepage</UIButton>
            </a>
          </div>
        </div>
      </UIContainer>
    );
  }
  return null;
};

export default UIQuestionnaireDashboardStatus;
