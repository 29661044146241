export const TOOLBOX_ANSWERS = [
  ['DDA7655A-CEDF-4806-B747-F88DBFA5395E', 'John Sample-User'],
  ['5C7D4EFB-87FF-44B9-9D54-80755211DA91', '7874939139'],
  ['AFA9C8CB-C959-4D47-BCD7-A63BA6724A5E', 'sample.user+5944173@trajector.com'],
  ['6028fb24-9d2f-4b64-875e-fda1b46a20a7', '234-23-4234'],
  ['8ad93941-1bb8-4505-b80d-1825bbd4485b', '123 asdf'],
  ['452fdc5b-282b-492b-9df4-f128c2674ccc', 'asdfasdf'],
  ['da173402-c5b1-4494-a648-47b56bab77ec', 'TX'],
  ['5ddef81d-e0dd-4e7f-8dd9-20e78ca91708', '34234'],
  ['5c9d0b64-ce8c-4b60-9f0d-71ad3bda1b93', 'US'],
  ['0ee95ae5-250b-4748-9e1f-4d1179ead9e0', 'prev_filed_yes'],
  ['08351642-f689-465f-a6da-35d6e5e174cc', 'asdfasdf'],
  ['cdfb5e9a-63e3-4c39-86e9-77ed8cfa76a1', 'claim_program_bdd'],
  ['8630f63e-1569-4bd7-a5a8-fc7bce61a31f', '1234-04-21'],
  ['3e1b923d-f303-4b8b-b486-5bbdff5a283a', 'currently_homeless_no'],
  [
    '6290c65c-b131-408a-888a-2689eceb2886',
    'currently_at_risk_of_homelessness_no',
  ],
  ['184e2e58-e19f-4909-9230-db04dde71fba', 'asdf'],
  ['009f031a-eb9b-4ec2-b2cd-6047b75cf348', '2342342345'],
  ['d956efc3-77af-40e7-9c90-c7ca0fa097ee', 'claiming_toxic_exposure_yes'],
  [
    'f1d9e955-245d-41a7-81d0-753b17d21606',
    'served_in_gulf_war_hazard_locations_no',
  ],
  ['2d962452-7338-4080-8d04-b286c0ff79ae', 'served_in_herbicide_locations_yes'],
  ['aeec7fea-f804-49ef-b047-bf8f32b071b6', '1999-03-23'],
  ['3b30191f-e233-4bfd-a49b-8e05e07ca452', '1999-03-23'],
  [
    '132d1fe5-daca-4efd-88fc-d09a9d5d872b',
    ['additional_exposure_sources_radiation'],
  ],
  ['cce38944-87d4-4758-b12c-b9d22c3504c0', '1999-03-23'],
  ['e9b2b9c1-38aa-4022-8f88-e3f13f743639', '1999-03-23'],
  ['9c43ced2-5a8c-4bae-9d9b-30c4e086e9ef', 'served_under_other_name_yes'],
  ['c8e24663-e6d9-4863-a494-91766af616da', 'asdfasdf'],
  ['85c3c036-65ec-4276-a280-0982c4eb828a', 'combat_zone_yes'],
  ['3f26cfc7-6741-4536-927e-e343d0d8eba3', 'asdfasdf'],
  ['457faa08-8411-42b6-80fe-91699175acb7', 'treatment_date_not_available1'],
  ['855a4352-7fd1-4dd5-b462-5b2e855ef879', 'treatment_date_not_available2'],
  ['3c85ffe6-1da8-4504-8554-93766bb12333', 'treatment_date_not_available3'],
  [
    '15234631-d15c-45ba-b14b-a9ea4235bcae',
    'currently_receiving_retired_pay_yes',
  ],
  [
    '77f778e3-3034-4aab-a561-fc9281401f12',
    ['retired_pay_branch_of_service_space_force'],
  ],
  ['37fa811b-59aa-46ea-b2d5-6ada0d7cf583', 123400],
  ['6801a0af-48dd-491e-9f6e-8f1395428ba3', 'receiving_retired_pay_future_no'],
  ['9065c83a-b2b6-42cc-ab74-7afc68fac4a4', 'asdfasdf'],
  [
    '0753a721-164f-4834-9095-0fa2cac3069b',
    'asdfasdfasdfasdfasdfasdf\nasdfasdfasdfasdfasdf\nasdfasdfasdfasdfasdfasdfasdfasdfasdf\nasdfasdfasdfasdfasdfasdfasdfasdf',
  ],
  [
    '175952d7-9efb-477b-9d4c-b729f6f40f5b',
    'asdfasdfasdfasdfasdfasdf\nasdfasdfasdfasdfasdfasdfasdf\nasdfasdfasdfasdfasdfasdfasdf\nasdfasdfasdfasdfasdfasdfasdfasdf\nasdfasdfasdfasdfasdf',
  ],
  ['2e93befb-45ff-4d10-80a7-721d4adde0b3', 'asdfasdfasdfasdf'],
  ['f35d621d-bd35-46d7-8aaa-38ee2493d0b6', '1234-02-12'],
  ['0f750d25-e07a-4189-87f3-3adcabbde77d', '1235-03-12'],
  ['5057d996-f500-4950-b7e1-6a8ac916c8bf', 'asdfasdfasdfasdfasdfasdfasdfasdf'],
  ['1e52a479-6d6c-4c87-ae04-ad8921144ab6', '1234-03-12'],
  ['0f6f55bc-5500-4c4f-ae48-cef213446c0b', '1234-02-12'],
  ['e52e874c-24ce-4810-a338-8f47bb74e96f', '1235-02-12'],
  ['4db05daf-2e16-4572-998e-4b0acf11649b', '1235-01-12'],
  ['8d40d536-3bd4-4319-83a7-cb3afdab40e4', 'activated_on_federal_orders_no'],
  ['a4dfb3a1-4833-4695-9538-40358a207ddf', 'receiving_inactive_pay_yes'],
  ['d1715056-0b68-4a51-996d-242249d91c2a', 'pow_yes'],
  ['2b0cfb70-54c4-4b70-9dcf-cbe96d667d52', '2132123123'],
  ['55cd39c9-8b49-43a8-a8fa-682af8c83277', '1234-01-12'],
  ['f13e9e2a-2a9a-47c4-87e2-d4bc02445eba', '1234-02-12'],
  ['a6ee2955-5ca2-4a2e-868d-98e2bb0807a8', '1234-02-12'],
  ['c5960546-efcb-44ee-a6fc-e189a4a14931', '1234-01-12'],
  ['717ca523-1bd4-46bb-a725-687d880f3289', 'asdfasdfa'],
  ['54843b9b-87e4-45d0-943f-616848b74769', '1234-02-12'],
  ['99a3b35e-d2c1-4555-9bb0-6a23d8192a01', 'asdfasdfasdfasdf'],
  ['89a52b90-58f3-4d7e-b80e-6314a1cc791d', '1235-02-12'],
  ['47ef54b9-587f-41b9-b496-06eba6d3ffc7', 'asdfasdfasdfasdfasdf'],
  ['e0924784-59a2-40a2-9f54-82c275377025', '1234-02-12'],
  ['b8803f36-8587-4a24-a645-8b2304595607', 'separation_pay_no'],
  ['739c82a6-0407-4aa0-ab49-ff7cccdc0a53', 'do_not_waive_retired_pay_yes'],
  ['52be4a4d-b786-413f-ab13-5bc60dc2b812', 'do_not_waive_inactive_pay_yes'],
  ['ad3ab6a8-277f-4e7f-9aed-b318be3f7b3e', 'agreed_to_atomic_forms_tos'],
  ['76eafd2a-5134-4df4-be2f-5120b419a825', 'type_of_address_change_temporary'],
  ['15cab9f0-64ae-4c8c-9476-3370b70b5b45', 'asdfasdfasdfasdf'],
  ['0f9038c2-6177-4793-b1b8-ba90ae283fcc', 'asdfa'],
  ['3361201c-725b-47d2-a50f-f56088dcc457', 'asdfasdfasdf'],
  ['9d0773c3-f80c-4d15-b94d-f5f37a35cbf2', 'as'],
  ['6f480a59-6e6c-4322-9076-9a22e317154a', 'as'],
  ['e47551db-b881-403a-bc52-c74490ef76db', '12345'],
  ['208f1594-9cc1-4019-a6f2-1ed573cb71cd', '1234-03-12'],
  ['7e8ac4e2-f587-49b5-92d0-1eb384062621', '1235-05-12'],
  ['82B1A3D8-FC3C-4443-BED9-36DC3F91CA3C', '1234-02-12'],
  ['b433bcd5-add3-41eb-9700-5cccbc109884', 'currently_va_employee_yes'],
  ['32404776-afd8-49ad-932e-5a2871a36f2a', '34234234'],
  ['b467989e-7a47-4d23-b8ee-338ea5595407', 'electronic_correspondence'],
];
