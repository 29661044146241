import {
  UIButton,
  UIContainer,
  UIHeader,
  UIParagraph,
  useNotification,
} from '@team-and-tech/ui-components';
import {
  ChevronUpIcon,
  ArrowLeftCircleIcon,
} from '@heroicons/react/24/outline';
import { Link, useNavigate } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { useState } from 'react';
import UIComparisonModal from './UIComparisonModal';
import UIConfirmationModal from './UIConfirmationModal';
import { usePathSelectionSubmit } from '../api/mutations/pathSelectionSubmit';

const UILegalDetails = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const navigate = useNavigate();
  const { createNotification } = useNotification();
  const { mutate: submitLead, isLoading: mutateIsLoading } =
    usePathSelectionSubmit('legal', {
      onSuccess: () => navigate('../legal-success'),
      onError: () =>
        createNotification('Something went wrong, try again.', 'error'),
    });
  return (
    <>
      <UIContainer
        verticalAlignment="center"
        header={
          <div className="flex flex-row items-center">
            <Link to="../plans" className="block">
              <ArrowLeftCircleIcon className="text-primary h-8 w-8 mr-3" />
            </Link>
            <UIHeader level="1">Lawyer in Your Corner</UIHeader>
          </div>
        }
      >
        <div className="space-y-6">
          <UIParagraph className="my-3">
            Our “legal backup” option for veterans who can develop their own
            medical evidence.
          </UIParagraph>
          <div className="flex flex-col">
            <Disclosure defaultOpen>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`flex w-full px-4 py-3 text-left text-lg hover:bg-brand-blue-100 focus:outline-none focus-visible:ring focus-visible:ring-primary items-center ${
                      open ? 'bg-brand-blue-100' : ''
                    }`}
                  >
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-gray-500 mr-3`}
                    />
                    What Do I Get?
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-gray-700 pl-5 sm:pl-10 py-5">
                    <ul className="list-disc ml-3 space-y-1">
                      <li>
                        <span className="font-bold">Data-driven analysis</span>{' '}
                        of your symptoms and medical history
                      </li>
                      <li>
                        <span className="font-bold">
                          Complete claim filing service
                        </span>
                        —yes, our lawyers file your claims for you!
                      </li>
                      <li>
                        <span className="font-bold">
                          Experienced, VA-savvy lawyer representation
                        </span>{' '}
                        ready to help with any needed appeals
                      </li>
                      <li>
                        <span className="font-bold">
                          In-depth review of your entire VA claims file
                        </span>{' '}
                        and your VA decision.
                      </li>
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`flex w-full px-4 py-3 text-left text-lg hover:bg-brand-blue-100 focus:outline-none focus-visible:ring focus-visible:ring-primary items-center ${
                      open ? 'bg-brand-blue-100' : 'border-t-2 border-blue-100'
                    }`}
                  >
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-gray-500 mr-3`}
                    />
                    What Do I Pay?
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-gray-700 pl-5 sm:pl-10 py-5">
                    <UIHeader level="4" className="sm:-ml-3 mb-2">
                      If I’m Approved on the First Try:
                    </UIHeader>
                    <UIParagraph>You pay nothing.</UIParagraph>
                    <UIHeader level="4" className="sm:-ml-3 mb-2 mt-3">
                      If I’m Denied:
                    </UIHeader>
                    <UIParagraph>
                      Our lawyers handle your appeal. If they succeed, your fee
                      will be equal to one-third of your awarded back pay. But
                      if you’re denied again, you pay nothing.
                    </UIParagraph>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`flex w-full px-4 py-3 text-left text-lg hover:bg-brand-blue-100 focus:outline-none focus-visible:ring focus-visible:ring-primary items-center ${
                      open ? 'bg-brand-blue-100' : 'border-t-2 border-blue-100'
                    }`}
                  >
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-gray-500 mr-3`}
                    />
                    How Long Will it Take?
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-gray-700 pl-5 sm:pl-10 py-5">
                    <ul className="list-disc ml-3 space-y-1">
                      <li>Our lawyers start preparing your claim right away</li>
                      <li>
                        <span className="font-bold">If needed</span>, they’ll
                        continue their work after your new decision.
                      </li>
                    </ul>
                    {/* <UIHeader level="4" className="sm:-ml-3 mb-2 mt-3">
                      Approximate Time to Filing
                    </UIHeader>
                    <UIParagraph>XXXX</UIParagraph> */}
                    <UIHeader level="4" className="sm:-ml-3 mb-2 mt-3">
                      Approximate Time to Completion:
                    </UIHeader>
                    <UIParagraph>
                      Variable. If your claim goes to appeal, this option may
                      take as long as 12-18 months.
                    </UIParagraph>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`flex w-full px-4 py-3 text-left text-lg hover:bg-brand-blue-100 focus:outline-none focus-visible:ring focus-visible:ring-primary items-center ${
                      open ? 'bg-brand-blue-100' : 'border-t-2 border-blue-100'
                    }`}
                  >
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 text-gray-500 mr-3`}
                    />
                    Is This Right for Me?
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-gray-700 pl-5 sm:pl-10 py-5">
                    <UIHeader level="4" className="sm:-ml-3 mb-2">
                      We recommend Lawyer in Your Corner to:
                    </UIHeader>
                    <ul className="list-disc ml-3 space-y-1">
                      <li>
                        Veterans who already have all their medical evidence
                      </li>
                      <li>
                        Veterans who want someone else to handle filing their
                        claim
                      </li>
                      <li>
                        Veterans who know they want “legal muscle” for appeals
                      </li>
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
          <UIButton use="primary" onClick={() => setConfirmationOpen(true)}>
            Get Started With This Plan
          </UIButton>
          <UIParagraph>
            Still Deciding?{' '}
            <span
              onClick={() => setModalOpen(true)}
              className="text-primary underline cursor-pointer"
            >
              Compare Plans
            </span>
          </UIParagraph>
        </div>
      </UIContainer>
      <UIComparisonModal open={modalOpen} setOpen={setModalOpen} />
      <UIConfirmationModal
        open={confirmationOpen}
        setOpen={setConfirmationOpen}
        callback={() => submitLead()}
        loading={mutateIsLoading}
        content={
          <>
            You chose: <span className="font-bold">Lawyer In Your Corner</span>
          </>
        }
        title="Ready to get started with this plan?"
      />
    </>
  );
};
export default UILegalDetails;
