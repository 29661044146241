import { Answers } from '@team-and-tech/trajector-cloud-api-client';
import getQuestionnaireDataById from '../../questionnaires/getQuestionnaireDataById';
import { USER_DATA_QUESTION_IDS } from '../../api/queries/session';
import { Survey } from '../../questionnaires/types';
import { intersection } from '../../lib/intersection';

export type QuestionnaireCompletionStatus =
  | 'not_started'
  | 'in_progress'
  | 'complete';

export const getQuestionnaireCompletionStatus: (
  surveyKey: Survey,
  questionResponses?: Answers['values'],
  answers?: Map<string, any>,
) => QuestionnaireCompletionStatus = (
  surveyKey,
  questionResponses,
  answers,
) => {
  const { questionsMap } = getQuestionnaireDataById(surveyKey);
  const answeredQuestions = new Set(Object.keys(questionResponses || {}));
  const questionsInSurvey = new Set(questionsMap.keys());

  if (intersection(answeredQuestions, questionsInSurvey).size !== 0) {
    return 'complete';
  }
  // We need to pull out the session values that we're injecting
  // into the answers value in the store from the session request
  // to prevent false display of 'in_progress' for background on start
  const answersWithoutSessionValues = Object.values(
    USER_DATA_QUESTION_IDS,
  ).reduce((output, qId: string) => {
    output.delete(qId);
    return output;
  }, new Map(answers));

  const setOfIdsInState = new Set(
    answersWithoutSessionValues?.keys() || new Map().keys(),
  );
  if (intersection(questionsInSurvey, setOfIdsInState).size !== 0) {
    return 'in_progress';
  }

  return 'not_started';
};
