import { useMutation, useQueryClient } from '@tanstack/react-query';
import useSessionQuery from '../queries/session';
import useStore from '../../app/store';
import { mapAnswersToPayload } from './submitAnswers';
import { getClient } from '../client';
import { Survey } from '../../questionnaires/types';
import { QuestionnaireToIdMap } from '../../questionnaire-dashboard/questionnaire-id-mapping';

export const useQuestionnaireSelectionSubmit = () => {
  const { data: session } = useSessionQuery();
  const { token } = useStore();
  const queryClient = useQueryClient();

  const client = getClient();

  return useMutation({
    mutationFn: (selectedQuestionnaires: Survey[]) => {
      const map = new Map(
        selectedQuestionnaires.map((questionnaire) => [
          QuestionnaireToIdMap.get(questionnaire) || '',
          `selected_${questionnaire}`,
        ]),
      );

      return client.createUserAnswers(
        session?.user.user_id || '',
        mapAnswersToPayload(map),
        { headers: { 'X-Trajector-Invite': token } },
      );
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['session'] }),
  });
};
